import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { HELP_URL } from '@aps-management/primapp-common';
import {
  Button,
  Typography,
  Paper,
  Grid,
  Divider,
} from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Screen } from '_components/core';

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
    maxWidth: 600,
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 3,
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 40,
    marginRight: theme.spacing.unit * 2,
  },
  title: {
    fontWeight: 600,
  },
  section: {
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    margin: theme.spacing.unit * 2,
  },
  label: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  value: {
    fontWeight: 400,
  },
  actions: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const Confirmation = ({ classes }) => {
  const location = useLocation();
  const history = useHistory();
  const { bookingInfo, paymentMethod } = location.state || {
    bookingInfo: {
      selectedSlot: 'Non spécifié',
      options: [],
      activity: null,
      resourceName: 'Ressource',
      price: 0,
    },
    paymentMethod: 'Carte bancaire',
  };

  const handleBackToHome = () => {
    history.push('/home');
  };

  const handleViewBookings = () => {
    history.push('/myactivities');
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const formatTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleTimeString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const activityDate = bookingInfo.startsAt
    ? formatDate(bookingInfo.startsAt)
    : 'Non spécifié';

  const activityTime = bookingInfo.startsAt && bookingInfo.endsAt
    ? `${formatTime(bookingInfo.startsAt)} - ${formatTime(bookingInfo.endsAt)}`
    : bookingInfo.selectedSlot || 'Non spécifié';

  const paymentText = () => {
    switch (paymentMethod) {
      case 0:
        return 'Paiement par carte bancaire';
      case 1:
        return 'Paiement sur place';
      default:
        return 'Paiement par compte client';
    }
  };

  return (
    <Screen
      noDrawer={false} // Assuming isPublic is false
      layout={'fluid-large'}
      ForwardIcon={null} // Assuming ForwardIcon is not defined
      helpURL={`${HELP_URL}/r%C3%A9server`}
      onRetry={null} // Assuming load is not defined
      title="Activités"
      error={null} // Assuming error is not defined
    >
      <Paper className={classes.root} elevation={2}>
        <div className={classes.header}>
          <CheckCircleIcon className={classes.icon} />
          <Typography variant="h5" component="h1" className={classes.title}>
            Réservation confirmée !
          </Typography>
        </div>

        <div className={classes.section}>
          <Typography variant="h6" gutterBottom>
            Détails de la réservation
          </Typography>

          <Grid container spacing={16}>
            {bookingInfo && (
              <>
                <Grid item xs={5}>
                  <Typography className={classes.label}>Activité :</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.value}>{`${bookingInfo?.activity?.name} (${bookingInfo?.resourceName})`}</Typography>
                </Grid>
              </>
            )}

            <Grid item xs={5}>
              <Typography className={classes.label}>Date :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.value}>{activityDate}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography className={classes.label}>Horaire :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.value}>{activityTime}</Typography>
            </Grid>
          </Grid>

          {bookingInfo.options && bookingInfo.options.length > 0 && (
            <>
              <Divider className={classes.divider} />
              <Typography variant="h6" gutterBottom>
                Options sélectionnées
              </Typography>
              <Grid container spacing={8}>
                {bookingInfo.options.map((option, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography variant="body2">{option}</Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <Divider className={classes.divider} />

          {bookingInfo && bookingInfo.price !== 0 && (
              <>
              <Typography variant="h6" gutterBottom>
                  Moyen de paiement
              </Typography>
              <Grid container spacing={16}>
                <Grid item xs={5}>
                  <Typography className={classes.label}>Moyen de paiement :</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.value}>{paymentText()}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className={classes.label}>Montant total :</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.value} style={{ fontWeight: 600 }}>
                    {bookingInfo.price ? `${(bookingInfo.price / 100).toFixed(2)} €` : 'Gratuit'}
                  </Typography>
                </Grid>
              </Grid>
          </>
          )}
        </div>

        <div className={classes.actions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBackToHome}
          >
            {"Retour à l'accueil"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleViewBookings}
          >
            {'Voir mes réservations'}
          </Button>
        </div>
      </Paper>
    </Screen>
  );
};

export default withStyles(styles)(Confirmation);
