import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  HELP_URL,
  helpers,
  Paybox,
  Booking,
  CreditCard,
  Activity,
} from '@aps-management/primapp-common';
import masterClient from '_utils/masterClient';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
/* */
import i18n from '_utils/i18n';
import Payment from '_components/Payment';
import { Screen } from '_components/core';
import { ArrowForwardIos as ForwardIcon } from '@material-ui/icons';
import apolloClient from '_utils/apolloClient';
import { DialogPaymentIP } from '_components/elements';
import { useContextSelector } from '@fluentui/react-context-selector';
import ActivityContext from './contexts/ActivityContext';

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
    maxWidth: 600,
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 3,
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 40,
    marginRight: theme.spacing.unit * 2,
  },
  title: {
    fontWeight: 600,
  },
  section: {
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    margin: theme.spacing.unit * 2,
  },
  paymentMethod: {
    marginBottom: theme.spacing.unit * 2,
  },
  actions: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  value: {
    fontWeight: 400,
  },
});

const ActivityPayment = ({ account, golf }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    error,
    setError,
    load,
    isPublic,
  } = useContextSelector(ActivityContext, (c) => c);
  const [waiting, setWaiting] = useState(false); // Add a state for waiting
  const [imprint, setImprint] = useState(null); // Add a state for imprint
  const [status, setStatus] = useState(null); // Add a state for status
  const { selectedSlot, bookingInfo, players } = location.state || {
    selectedSlot: 'Non spécifié',
    bookingInfo: null,
    players: [],
  };

  useEffect(() => {
    CreditCard.api.getCreditCards(apolloClient, {})
      .then(({ creditCards }) => {
        if (creditCards[0]) {
          const newStatus = CreditCard.functions.getStatus(creditCards[0].dueDate);
          setImprint({
            ...newStatus,
            ...creditCards[0],
          });
        }
      });
  }, [status]);

  const capture = (data, creditCard) => {
    if (creditCard.saveImprint) {
      const uuid = Paybox.functions.createUuidFrom(account.id);

      return Paybox.api.subscribeAndCapture(apolloClient, {
        uuid,
        id3D: data.id3D,
        amount: data.amount,
        reference: account.id,
        creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
      })
        .catch((err) => {
          throw Object.assign(err, { onSubscribe: true });
        })
        .then((res) => CreditCard.api.setCreditCard(apolloClient, {
          id: uuid,
          token: res.PORTEUR,
          number: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'country', 'brand', 'type']),
        })
          .then(() => res))
        .catch((err) => {
          if (!err.onSubscribe) {
            throw Object.assign(err, {
              message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
            });
          }
          throw err;
        });
    }

    return Paybox.api.capture(apolloClient, {
      id3D: data.id3D,
      reference: data.id,
      amount: data.amount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    });
  };

  const payWithCardImprint = () => {
    const subscriber = {
      id: imprint.id,
      token: imprint.token,
      dueDate: imprint.dueDate,
    };

    const data = {};

    setError(null);
    setWaiting(true);
    setStatus('Nous prenons votre réservation au golf...');

    return Activity.api.activityBook(masterClient, {
      ...location.state,
      golf,
      players: location.state.players.map((player, index) => {
        if (index === 0) {
          return ({
            ...account,
            ...player,
          });
        }
        return ({
          ...player,
        });
      }),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then((res) => {
        data.id = res.id;
        data.amount = res.total.price;
        setStatus('Nous interrogeons votre banque...');

        return Paybox.api.captureSubscriber(apolloClient, {
          amount: data.amount,
          reference: data.id,
          subscriber,
        });
      })
      .catch((err) => {
        if (!err.handled) {
          setError('Erreur lors de la transaction, annulation en cours...');
          return Booking.api.bookingCancel(apolloClient, { id: data.id, failed: true })
            .then(() => { throw Object.assign(err, { handled: true }); })
            .catch(() => { throw Object.assign(err, { handled: true }); });
        }
        throw err;
      })
      .then((res) => {
        data.transaction = {
          amount: data.amount,
          card: imprint.number,
          callNo: res.NUMAPPEL,
          creditCardId: imprint.id,
          transactionNo: res.NUMTRANS,
          ...helpers.object.filterByKeys(imprint, ['type', 'brand', 'country']),
        };

        return Booking.api.bookingConfirm(apolloClient, {
          id: data.id,
          transaction: data.transaction,
        });
      })
      .catch((err) => {
        if (!err.handled) {
          return data;
        }
        setError(err.message);
        setWaiting(false);
        throw err;
      })
      .then(({ transaction }) => {
        history.push({
          pathname: '/activity/confirmation',
          state: {
            ...location.state,
            ...data,
            transaction,
          },
        });
      })
      .catch((err) => {
        if (!err.handled) {
          setError(err.message);
          setWaiting(false);
        }
      });
  };

  const payWithNewCard = (values, { setSubmitting }) => {
    const { id3D = null } = values;

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    const data = { id3D };

    setError(null);
    setWaiting(true);
    setStatus('Nous prenons votre réservation au golf...');
    return Activity.api.activityBook(masterClient, {
      ...location.state,
      golf,
      players: location.state.players.map((player, index) => {
        if (index === 0) {
          return ({
            ...account,
            ...player,
          });
        }
        return ({
          ...player,
        });
      }),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then((res) => {
        data.id = res.id;
        data.amount = res.total.price;
        setStatus('Nous interrogeons votre banque...');

        return capture(data, creditCard);
      })
      .catch((err) => {
        if (!err.handled) {
          setStatus('Erreur lors de la transaction, annulation en cours...');
          setWaiting(false);

          return Booking.api.bookingCancel(apolloClient, { id: data.id, failed: true })
            .then(() => { throw Object.assign(err, { handled: true }); })
            .catch(() => { throw Object.assign(err, { handled: true }); });
        }
        throw err;
      })
      .then((res) => {
        data.transaction = {
          amount: data.amount,
          callNo: res.NUMAPPEL,
          transactionNo: res.NUMTRANS,
          card: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['type', 'brand', 'country']),
        };

        return Booking.api.bookingConfirm(apolloClient, {
          id: data.id,
          transaction: data.transaction,
        });
      })
      .catch((err) => {
        if (!err.handled) {
          return data;
        }
        setStatus(err.message);
        setWaiting(false);
        throw err;
      })
      .then(({ transaction }) => {
        history.push({
          pathname: '/activity/confirmation',
          state: {
            ...location.state,
            ...data,
            transaction,
          },
        });
      })
      .catch((err) => {
        if (!err.handled) {
          setStatus(err.message);
          setWaiting(false);
        }
        setSubmitting(false);
      });
  };

  const session3DS = {
    address: {
      city: golf.city,
      countryCode: 250, // golf.country
      line1: golf.name,
      zipCode: golf.postalCode,
    },
    customer: {
      email: account.email,
      lastName: account.lastname,
      firstName: account.firstname,
      phone: account.phone,
    },
  };

  const numberOfPlayers = players?.length || 0;

  const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(bookingInfo.startsAt)));
  const courseName = bookingInfo.resourceName;
  const detailsTxt = `${i18n.t('terms.players', { count: numberOfPlayers })}`;

  return (
    <Screen
      noDrawer={isPublic}
      layout={'fluid-large'}
      ForwardIcon={ForwardIcon}
      helpURL={`${HELP_URL}/r%C3%A9server`}
      onRetry={load}
      title="Paiement par CB"
      error={error}
    >
      <Typography
        align="center"
        component="h2"
        variant="h5">
        {`${dateTxt} • ${selectedSlot}`}
      </Typography>
      <Typography
        align="center"
        component="p"
        color="textSecondary"
        variant="h6">
        {courseName}
      </Typography>
      <Typography
        align="center"
        component="p"
        variant="body1">
        {detailsTxt}
      </Typography>
      <br />
      <Payment
        amount={bookingInfo.price}
        imprint={imprint}
        currency={bookingInfo.currency || 'EUR'}
        session3DS={session3DS}
        payWithNewCard={payWithNewCard}
        payWithCardImprint={payWithCardImprint} />
      <DialogPaymentIP open={waiting} status={status} />
    </Screen>
  );
};

const mapStateToProps = ({ app, bookingData }) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
});

const StyledComponent = withStyles(styles)(ActivityPayment);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
