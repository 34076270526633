// RenderButton.js
import React from 'react';
import { Button } from '@material-ui/core';
import { useContextSelector } from '@fluentui/react-context-selector';
import { Activity, App, Booking } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import ActivityContext from '../../contexts/ActivityContext';

const RenderButton = ({ account, golf }) => {
  const {
    setShowDisponibilities,
    selectedActivity,
  } = useContextSelector(ActivityContext, (c) => c);
  const isMember = App.functions.isMember(account, golf);
  const hasMaxDays = (isMember
    ? selectedActivity?.dayMember
    : selectedActivity?.dayGuest) !== -1;

  return (
    <Button
      fullWidth
      size="large"
      color="secondary"
      variant="contained"
      disabled={!hasMaxDays}
      onClick={() => setShowDisponibilities(true)}
    >
      {hasMaxDays ? 'Voir les disponibilités' : 'Aucunne disponibilités'}
    </Button>
  );
};

const mapStateToProps = ({
  app, bookingData, bookingSearch, activityData,
}) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
  search: bookingSearch,
  activityData,
});

const combinedActions = { ...Booking.actions, ...Activity.actions };

export default connect(
  mapStateToProps,
  combinedActions,
)(RenderButton);
