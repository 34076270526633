import React from 'react';
import { ActivityNewSelector } from '_components/elements';
import { useContextSelector } from '@fluentui/react-context-selector';
import ActivityContext from '../../contexts/ActivityContext';

const SetActivity = () => {
  const {
    activities,
    selectedActivity,
    changeActivity,
  } = useContextSelector(ActivityContext, (c) => c);

  return (
    <ActivityNewSelector
      key={activities.length}
      noDefault
      data={activities}
      value={selectedActivity?.id}
      onChange={(newIndex) => {
        if (activities[newIndex - 1].disabled) return;
        changeActivity(activities[newIndex - 1]);
      }}
    />
  );
};

export default SetActivity;
