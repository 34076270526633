import React from 'react';
import _ from 'lodash/fp/lang';
import { connect } from 'react-redux';
import { App, Account, Document } from '@aps-management/primapp-common';
import { PublicBookingProvider } from './screens/Public/PublicBooking/services/publicBookingService';
import { BrowserRouter } from 'react-router-dom';
// import config from '_config';
import * as Screens from '_screens';
import I18n from '_utils/i18n';
import Router from '_config/router';
import Firebase from '_utils/firebase';
import apolloClient from '_utils/apolloClient';
import masterClient from '_utils/masterClient';
import Manifest from './utils/manifest';


/* */
class Bootstrap extends React.PureComponent {
  /* */
  constructor(props) {
    super(props);

    this.slug = null;

    Firebase.init();
    Firebase.connection();
  }

  /* */
  componentDidMount() {
    const { slug } = this.props.match.params;
    this.slug = slug;

    // Load APP GOLF
    if (this.slug) {
      this.props.loadGolf(apolloClient, { id: this.slug })
        .then((g) => {
          if (g) {
            I18n.locale = g.locale;
            global.origin = `${g.slug}`.toUpperCase();
          } else {
            this.props.noGolf();
          }
        });
    } else {
      this.props.noGolf();
      this.props.noAccount();
    }
  }

  /* */
  componentDidUpdate(prevProps) {
    const { user, golf } = this.props;

    document.title = (golf && golf.name) || 'prima.golf';

    if (golf && user && !_.isEqual(user, prevProps.user)) {
      // Load ACCOUNT
      if (user.accountId) {
        this.props.loadAccount(apolloClient, { id: user.accountId })
          .then(({ payload }) => {
            const { license } = payload?.ffg || { license: null };
            let promiseLicence = Promise.resolve(null);
            if (license) {
              promiseLicence = Account.api.getHcpIndexFFG(apolloClient, {});
            }
            return Promise.allSettled([
              promiseLicence,
              Document.api.getDocumentAcceptance(masterClient,
                { accountId: payload.id, golfId: golf.id }),
            ]);
          })
          .then(([ffg, documentAcceptance]) => this.props.updateAccount({
            ...(ffg.value && { ffg: ffg.value }),
            documentAcceptance: documentAcceptance.value,
          }));
      } else {
        this.props.noAccount();
      }
    } else if (!user && !_.isEqual(user, prevProps.user)) {
      this.props.noAccount();
    }

    // Reload golf after logout
    if (golf === null) {
      if (this.slug) {
        this.props.loadGolf(apolloClient, { id: this.slug })
          .then((g) => {
            I18n.locale = g.locale;
          });
      }
    }
  }

  /* */
  retry = () => {
    Firebase.disconnect();
    Firebase.connection();
  };

  /* */
  renderRoutes() {
    const {
      golf,
      user,
      error,
      account,
      loading,
    } = this.props;

    if (error) {
      return <Screens.Public.Error error={error} retry={this.retry} />;
    }

    // App loading
    if (loading) return <Screens.Loading />;

    if (user && !account) {
      return <Screens.Auth.CreateAccount />;
    }

    const routerProps = { golf, user, account };

    return (
      <Router {...routerProps} />
    );
  }

  /* */
  render() {
    const { golf } = this.props;

    // Golf loading
    if (golf === null) return <Screens.Loading />;

    if (!golf) return <Screens.Public.NotFound />;

    return (
      <PublicBookingProvider golf={golf}>
        <BrowserRouter basename={`/${this.slug}`}>
          {this.slug && (<Manifest golf={golf} />)}
          {this.renderRoutes()}
        </BrowserRouter>
      </PublicBookingProvider>
    );
  }
}

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(
  mapStateToProps,
  App.actions,
)(Bootstrap);
