import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import { Block } from "@material-ui/icons";
import { useContextSelector } from "@fluentui/react-context-selector";
import PublicBookingContext from "./services/publicBookingService";
import { unstable_Box as Box } from "@material-ui/core/Box";
import { Booking } from "@aps-management/primapp-common";
import { useScaling } from "_utils/scalingUtil";

/* */
const styles = (theme) => ({
  avatar: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    flexGrow: 1,
  },
  container: {
    backgroundColor: "transparent",
    display: "grid !important",
    maxHeight: "calc(100vh - 150px)",
    minHeight: "calc(100vh - 150px)",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "0.05fr 35px 0.95fr",
    gridTemplateAreas: `
        "title title title"
        "header header header"
        "main main main"`,
  },
  listItem: {
    padding: 0,
  },
  listItemRoot: {
    padding: 0,
    marginLeft: 4,
    marginRight: 4,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxHeight: "100%",
  },
  label: {
    margin: 0,
  },
  containerTablet: {
    maxHeight: "calc(64vh - 200px)",
    overflowY: "hidden",
  },
  containerMobile: {
    maxHeight: "calc(64vh - 185px)",
    overflowY: "hidden",
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  button: {
    padding: 0,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  gridList: {
    display: "flex",
    flexWrap: "nowrap !important",
  },
});

const PublicBookingList = ({ golf, classes, discountOnly, ...props }) => {
  const { scaleH } = useScaling();
  const {
    history,
    reset,
    setGolf,
    setDate,
    setCourse,
    setBooking,
    setActivity,
    addPlayer,
  } = props;
  const {
    loading: publicBookingLoading,
    data: { coursesSchedule },
    players,
    playerCount,
    selectedDate,
    selectedCourse,
  } = useContextSelector(PublicBookingContext, (c) => c);

  const onBook = (date, course, time, ps, px, ip) => {
    const formattedDate = date.format("YYYY-MM-DD");
    reset();
    setGolf({
      ...golf,
      options: Object.keys(golf.options).map((o) => {
        return {
          name: o,
          value: golf.options[o],
        };
      }),
    });
    setDate(formattedDate);
    setCourse(course);
    setActivity(1);
    ps.forEach((p, i) =>
      addPlayer({
        email: null,
        phone: null,
        lastname: null,
        firstname: null,
        isMember: false,
        type: i === 0 ? "owner" : "anonymous",
        initPrice: ip,
        price: px,
        tid: "UNKNOWN",
        reference: p,
      })
    );
    const booking = time;
    booking.from = "public-booking";
    booking.golf = golf;
    booking.date = date.format("YYYY-MM-DD");
    booking.course = course;
    booking.players = ps.map((p, i) => ({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: i === 0 ? "owner" : "anonymous",
      initPrice: ip,
      price: px,
      tid: "UNKNOWN",
      reference: p,
    }));
    // currency
    booking.total.currency = golf.currency;

    setBooking(booking);
    history.push("/register");
  };

  if (publicBookingLoading) {
    return (
      <Grid
        item
        container
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 24,
        }}
      >
        <CircularProgress className={classes.progress} />
      </Grid>
    );
  }
  if (
    coursesSchedule &&
    coursesSchedule.filter(
      (teetime) =>
        teetime.availability >= playerCount &&
        (discountOnly ? teetime.total.discount > 0 : true)
    )?.length <= 0
  ) {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: 24,
        }}
      >
        <Typography paragraph align="center" variant="subtitle1">
          {"Aucun créneau n'est disponible."}
        </Typography>
      </Box>
    );
  }
  return (
    <Grid
      container
      style={{
        gridArea: "content",
        position: "absolute",
        top: 0,
        bottom: 0,
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      <Grid
        style={{
          display: "grid",
          width: "100%",
          gridTemplateRows: "repeat(auto-fill, minmax(auto, 1fr))",
          alignItems: "flex-start",
          justifyItems: "center",
        }}
      >
        {coursesSchedule
          .filter(
            (teetime) =>
              teetime.availability >= playerCount &&
              (discountOnly ? teetime.total.discount > 0 : true)
          )
          .map((teetime, i) => (
            <ListItem
              disableGutters
              style={{
                padding: 0,
              }}
              key={`item-dispo-${teetime.time}-${i}`}
            >
              <ListItemText
                classes={{
                  root: classes.listItemRoot,
                }}
                style={{ textAlign: "center", flex: 0.2 }}
                primaryTypographyProps={{
                  style: {
                    width: "50px",
                    fontWeight: 500,
                    fontSize: "calc(10px + 0.390625vw)",
                  },
                }}
              >
                {Booking.functions.formatTime(teetime.time)}
              </ListItemText>
              <Box
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flex: 0.4,
                }}
              >
                {teetime.others.map((player, i) => (
                  <Box
                    key={`player-${player.index || "54.0"}-${i}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: scaleH(10),
                      width: scaleH(14),
                      minWidth: 28,
                      minHeight: 28,
                      margin: 2,
                      borderRadius: 5,
                      border: "1px solid #b8b8b8",
                      backgroundColor: "#71d187",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "calc(8px + 0.390625vw)",
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      {player.index
                        ? parseInt(player.index, 10).toFixed(1)
                        : "54.0"}
                    </Typography>
                  </Box>
                ))}
                {Array(parseInt(teetime.availability, 10))
                  .fill("")
                  .map((availability, i) => (
                    <Box
                      key={`availability-${availability}-${i}`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: scaleH(10),
                        width: scaleH(14),
                        minWidth: 28,
                        minHeight: 28,
                        margin: 2,
                        borderRadius: 5,
                        border: "1px solid #b8b8b8",
                      }}
                    >
                      {availability}
                    </Box>
                  ))}
                {teetime.players.length === 0 &&
                  parseInt(teetime.availability, 10) === 0 &&
                  Array(selectedCourse?.maxPlayers || 4)
                    .fill("")
                    .map((block, i) => (
                      <Box
                        key={`blocked-${block}-${i}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: scaleH(10),
                          width: scaleH(14),
                          minWidth: 28,
                          minHeight: 28,
                          margin: 2,
                          borderRadius: 5,
                          border: "1px solid #b8b8b8",
                          backgroundColor: "#f08484",
                        }}
                      >
                        <Block />
                      </Box>
                    ))}
                {teetime.others.length +
                  Array(parseInt(teetime.availability, 10)).fill("").length !==
                  selectedCourse?.maxPlayers &&
                  Array(
                    (selectedCourse?.maxPlayers || 4) -
                      (teetime.others.length +
                        Array(parseInt(teetime.availability, 10)).fill("")
                          .length)
                  )
                    .fill("")
                    .map((block, i) => (
                      <Box
                        key={`blocked-${block}-${i}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: scaleH(10),
                          width: scaleH(14),
                          minWidth: 28,
                          minHeight: 28,
                          margin: 2,
                          borderRadius: 5,
                          border: "1px solid #b8b8b8",
                          backgroundColor: "#f08484",
                          visibility: "hidden",
                        }}
                      >
                        <Block fontSize="small" />
                      </Box>
                    ))}
              </Box>
              <Box
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 0.2,
                }}
              >
                <ListItemText
                  classes={{
                    root: classes.listItemRoot,
                  }}
                  style={{ textAlign: "center" }}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "calc(6px + 0.390625vw)",
                      fontWeight: 500,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    },
                    color: "secondary",
                  }}
                  secondaryTypographyProps={{
                    style: {
                      fontSize:
                        teetime.total.discount > 0
                          ? "calc(6px + 0.390625vw)"
                          : "calc(8px + 0.390625vw)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      paddingRight: 0,
                      fontWeight: 500,
                      textDecoration:
                        teetime.total.discount > 0 ? "line-through" : "none",
                    },
                    color: "secondary",
                  }}
                  secondary={`${(
                    (teetime.total.price * playerCount) /
                    100
                  ).toFixed(2)} €`}
                  {...(teetime.total.discount > 0 && {
                    primary: `${(
                      (((teetime.total.price * teetime.total.discount) / 100) *
                        playerCount) /
                      100
                    ).toFixed(2)} €`,
                  })}
                />
                {teetime.total.discount > 0 && (
                  <ListItemText
                    style={{
                      textAlign: "center",
                      backgroundColor: "#e94c4c",
                      position: "absolute",
                      right: 25,
                      padding: 4,
                      paddingInline: 8,
                      borderRadius: 5,
                    }}
                    primaryTypographyProps={{
                      style: {
                        fontSize: "calc(8px + 0.390625vw)",
                        fontWeight: 700,
                        color: "white",
                      },
                    }}
                    primary={`${teetime.total.discount}%`}
                  />
                )}
              </Box>
              <ListItemIcon
                style={{
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "calc(8px + 0.390625vw)",
                  marginRight: 4,
                  flex: 0.2,
                }}
              >
                <Button
                  style={{
                    margin: 2,
                    padding: 3,
                    color: "white",
                    fontWeight: 600,
                    borderRadius: 5,
                    paddingInline: 6,
                    fontSize: "calc(8px + 0.390625vw)",
                  }}
                  size="small"
                  color="secondary"
                  variant="contained"
                  aria-label="book"
                  onClick={() =>
                    onBook(
                      selectedDate,
                      selectedCourse,
                      {
                        ...teetime,
                        total: {
                          ...teetime.total,
                          discount: teetime.total.discount * playerCount,
                          initPrice: teetime.total.initPrice * playerCount,
                          price: teetime.total.price * playerCount,
                        },
                      },
                      players,
                      teetime.total.price,
                      teetime.total.initPrice
                    )
                  }
                >
                  {"Reserver"}
                </Button>
              </ListItemIcon>
            </ListItem>
          ))}
      </Grid>
    </Grid>
  );
};
/* */
const mapStateToProps = ({ app: { golf }, bookingData, bookingSearch }) => ({
  golf,
  data: bookingData,
  search: bookingSearch,
});
export default connect(
  mapStateToProps,
  Booking.actions)(
  withRouter(withStyles(styles)(PublicBookingList))
);
