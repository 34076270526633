import React from 'react';
import { Paper, Typography } from '@material-ui/core';

const SearchOption = ({
  classes, label, onClick, value, warningMessage, warningComponent = null,
}) => (
  <Paper className={classes.optionContainer} onClick={onClick}>
    <div className={classes.optionContent}>
      <Typography gutterBottom color="inherit" component="span" variant="h6">
        {label}
      </Typography>
      <Typography className={classes.optionValue} component="span" noWrap variant="h5">
        {value}
      </Typography>
      {warningMessage && (
        <Typography color="error" component="p" variant="caption">
          {warningMessage}
        </Typography>
      )}
      {warningComponent}
    </div>
  </Paper>
);

export default SearchOption;
