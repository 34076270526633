import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Activity, Booking, HELP_URL } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { FaceOutlined } from '@material-ui/icons';
import { ActivityNewSelector } from '_components/elements';
import {
  Chip,
  Grid,
  Paper,
  Button,
  ButtonBase,
  Typography,
  CircularProgress,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import masterClient from '_utils/masterClient';
import { CalendarDay } from '_components/elements';

/* */
const styles = theme => ({
  item: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.unit * 2,
  },
  chip: {
    display: 'flex',
    borderColor: 'transparent',
    justifyContent: 'flex-start',
  },
  chipIcon: {
    marginLeft: 0,
    marginRight: theme.spacing.unit * -0.5,
  },
  canceled: {
    top: 16,
    right: -90,
    width: 250,
    overflow: 'hidden',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(40deg)',
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
  },
  itemContainer: { display: 'block' },
  itemOther: { backgroundColor: theme.palette.grey[50] },
  itemCanceled: { backgroundColor: theme.palette.grey[200] },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

const LIMIT = 3;

/* */
class BookingHistory extends React.Component {
  /* */
  state = {
    // Data
    activities: [],
    bookings: [],
    selectedActivity: null,
    last: [],
    comingSoon: [],
    // UX
    showMore: null,
    // Screen
    error: null,
    loading: true,
  };

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    this.setState({
      error: null,
      loading: true,
    });

    const { golf } = this.props;
    Activity.api.getActivities(masterClient, { golfId: golf.id }).then(async (activities) => {
      this.setState({
        activities,
        selectedActivity: this?.state?.selectedActivity || activities[0],
      });
      Activity.api.getActivityHistory(masterClient, { golfId: golf.id }).then(({ bookings = [] }) => {
        this.setState({ bookings });
  
        this.setState({
          loading: false,
        });
        this.handleChangeActivity(1);
      })
      .catch(e => {
        console.error(e)
        this.setState({ error: e.message })
      })
      .finally(() => this.setState({ loading: false, loadingMore: false }))
    })
  };

  handleChangeActivity = (newIndex) => {
    console.log('handleChangeActivity', newIndex);
    if (this.state.activities[newIndex - 1].disabled) return;
    this.setState({ selectedActivity: this.state.activities[newIndex - 1] });
    const last = [];
    const comingSoon = [];
    this.state.bookings.filter(b => b.activity.id === this.state.activities[newIndex - 1].id).forEach((b) => {
      const datetime = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HHmm');
      if (datetime.isBefore(moment(), 'hour')) {
        last.push(b);
      } else {
        comingSoon.push(b);
      }
  
      this.setState({
        last,
        comingSoon,
        ...(last.length > LIMIT && { showMore: false }),
      });
    });

  };

  /* */
  goToView = item => () => {
    this.props.detailBooking(item);
    this.props.history.push(`/myactivities/${item.id || 'details'}`);
  }

  /* */
  renderItem = (item, key) => {
    const { classes, golf } = this.props;
    const { activities } = this.state;

    const isCanceled = item.status === 'canceled';
    const fromAnother = item.golf.id !== golf.id;

    const {
      type: types,
      resources,
    } = activities.find(a => a?.id === item?.activity?.id || false) || {
      type: [],
      resources: [],
    };
    const resource =  resources.find(r => r.resourceId === item.activity.resourceId) || null;
    const ressourceName =  resource?.name || null;
    const type = types.find(t => t.typeId === item.activity.typeId) || null
    const typeName = type?.name || null;

    const bookingDate = moment(`${item.date} ${item.time}`, 'YYYY-MM-DD HHmm');

    return (
      <Grid
        item
        key={key}
        md={6} sm={10} xs={12}>
        <ButtonBase
          disableRipple
          component="div"
          onClick={this.goToView(item)}
          className={classes.itemContainer}>
          <Paper
            className={classNames(classes.item, {
              [classes.itemOther]: fromAnother,
              [classes.itemCanceled]: isCanceled,
            })}>
            <Box
              display="flex"
              flexDirection="row">
              <CalendarDay
                displayTime={true}
                datetime={bookingDate} />
              <Box ml={3} flex={1}>
                {fromAnother && (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    component="span">
                    {item.golf.name}
                  </Typography>
                )}
                <Typography
                  variant="subtitle1"
                  component="span">
                  {ressourceName}
                </Typography>
                <Typography
                  component="span"
                  variant="subtitle1">
                  {typeName}
                </Typography>
                {item.nbPlayers > 0 && (
                  <Chip
                    variant="outlined"
                    icon={<FaceOutlined />}
                    classes={{ icon: classes.chipIcon, outlined: classes.chip }}
                    label={`${i18n.t('terms.players', { count: item.nbPlayers })}`} />
                )}
              </Box>
            </Box>
            {isCanceled && (
              <Typography
                component="span"
                variant='caption'
                className={classes.canceled}>
                {'Annulée'}
              </Typography>
            )}
          </Paper>
        </ButtonBase>
      </Grid>
    );
  }

  /* */
  renderEmpty() {
    if (this.state.loading) {
      return (
        <Box
          textAlign="center" 
          style={{ with: '100%', height: '100%', marginTop: 20, marginBottom: 20, justifyContent: 'center' }}
        >
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Box textAlign="center">
        <Typography
          paragraph
          variant="h5"
          align="center"
          component="h2">
          {'Aucune réservation pour le moment.'}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.push('/booking')}>
          {'Réserver'}
        </Button>
      </Box>
    );
  }

  /* */
  renderHistory() {
    const { classes } = this.props;
    const { last, comingSoon, showMore, loading } = this.state;
    if ((last.length + comingSoon.length) === 0) {
      return this.renderEmpty();
    }

    // reduce array elements
    const renderedLast = (showMore === false)
      ? last.filter((l, idx) => idx < LIMIT)
      : last;

    return (
      <React.Fragment>
        {comingSoon.length > 0 && (
          <Box mb={4}>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {'Mes prochaines réservations'}
            </Typography>
            {loading ? (
              <Grid
                style={{ marginTop: 20, marginBottom: 20, justifyContent: 'center' }}
                container
                spacing={24}>
                <CircularProgress className={classes.progress} color="secondary" />
              </Grid>
            ) : (
              <Grid
                container
                spacing={24}>
                {comingSoon.map(this.renderItem)}
              </Grid>
            )}
          </Box>
        )}
        {last.length > 0 && (
          <Box mb={2}>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {'Mes réservations passées'}
            </Typography>
            {loading ? (
              <Grid
                style={{ marginTop: 20, marginBottom: 20, justifyContent: 'center' }}
                container
                spacing={24}>
                <CircularProgress className={classes.progress} color="secondary" />
              </Grid>
            ) : (
              <Grid
                container
                spacing={24}>
                {renderedLast.map(this.renderItem)}
              </Grid>
            )}
          </Box>
        )}
        {! loading && showMore === false && (
          <Button
            fullWidth
            color="secondary"
            onClick={() => this.setState({ showMore: true })}>
            {'Voir plus'}
          </Button>
        )}
      </React.Fragment>
    );
  }

  /* */
  render() {
    const { error, activities } = this.state;

    return (
      <Screen
        error={error}
        helpURL={`${HELP_URL}/mes-activit%C3%A9s`}
        title="Mes activités">
        <ActivityNewSelector
          key={activities.length}
          noDefault
          data={activities}
          value={this.state.selectedActivity?.id}
          onChange={this.handleChangeActivity}
        />
        {this.renderHistory()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

const StyledComponent = withStyles(styles)(BookingHistory);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
