import React from 'react';
import moment from 'moment';
import * as qs from 'query-string';
import fileDownload from 'js-file-download';
import FileDownloadIcon from '@material-ui/icons/GetApp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import {
  App,
  Paybox,
  Account,
  helpers,
  CreditCard,
  Subscription,
} from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import masterClient from '_utils/masterClient';
import masterApi from '_utils/master';
import GetCreditCard from '_components/GetCreditCard';
import { DialogPaymentIP, CreditCardPreview } from '_components/elements';

/* */
const styles = theme => ({
  stepper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  checkboxCtrl: {
    paddingTop: 4,
    paddingBottom: 4,
  },
});

class MembershipRenew extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.authorizationAmount = 1000;
    this.steps = ['Formule', 'Documents', 'Moyen de paiement', 'Confirmation'];

    this.state = {
      // ux
      error: null,
      success: null,
      loading: true,
      activeStep: 0,
      screenLoading: true,
      buttonLoading: false,
      // data
      golf: null,
      schedule: [],
      documents: [],
      account: null,
      creditCard: null,
      subscription: null,
      prevSubscription: null,
      approveDocuments: false,
      consent: {
        newsletterGolf: false,
        newsletterUGolf: false,
        newsletterLeClubGolf: false,
      },
    };
  }

  /* */
  componentDidMount() {
    this.load()
      .catch(error => this.setState({ error }))
      .finally(() => this.setState({ screenLoading: false }));
  }

  /* */
  download = (d) => {
    const { golf, account } = this.state;

    const headers = {
      'x-golf': golf.id,
      'x-account': account.id,
    };
    masterApi.blob(`document/${d.id}`, {}, { headers })
      .then((data) => {
        if (!data) throw new Error('Impossible de télécharger le document');

        return fileDownload(data, d.filename);
      })
      .catch(error => this.setState({ error }));
  };

  /* */
  getStepContent(step) {
    switch (step) {
      case 0:
      default:
        return this.renderPackageStep();
      case 1:
        return this.renderDocumentStep();
      case 2:
        return this.renderPaymentStep();
      case 3:
      case 4:
        return this.renderSummaryStep();
    }
  }

  /* */
  renderPaymentSchedule() {
    const { subscription: { schedule } } = this.state;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {'Date'}
              </TableCell>
              <TableCell align="right">
                {'Montant'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedule.map((l, i) =>
              <TableRow key={i}>
                <TableCell>
                  {i18n.l('date.formats.default', l.dueDate)}
                </TableCell>
                <TableCell align="right">
                  {i18n.l('currency', l.amount / 100)}
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  /* */
  load = async () => {
    const { token } = qs.parse(this.props.location.search);

    if (!token) { throw new Error('Lien erroné.'); }

    const account = await Account.api.getAccountByToken(apolloClient, { token });
    if (!account) throw new Error('Client inexistant.');

    const matching = account?.matches.find(m => (m.token === token ? m : false));

    const { golf } = await App.api.getGolfInfo(apolloClient, { id: matching.golfId });
    document.title = (golf && golf.name) || 'prima.golf';

    // set locale
    i18n.locale = golf.locale;
    this.currency = golf.currency;

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
        phone: account.phone,
      },
    };

    const prevSubscr = await Subscription.api.getLastSubscription(masterClient, {
      accountId: account.id,
      golfId: matching.golfId,
    });
    // N'est plus bloquant
    // if (!prevSubscr) throw new Error('Abonnement non trouvé.');

    // Inclus les 7 jours de délai
    const fromDate = moment().subtract('7', 'd').format('YYYY-MM-DD');
    // prevSubscr.expiresAt,

    const nextSubscr = await Subscription.api.getSubscription(masterClient, {
      accountId: account.id,
      golfId: matching.golfId,
      from: fromDate,
    });
    if (!nextSubscr) throw new Error('Offre inexistante ou expirée.');

    const lastStatus = nextSubscr.statuses[0];
    const isAlreadyComplete = lastStatus && lastStatus.name === 'completed';

    const [documents, { creditCards }] = await Promise.all([
      Subscription.api.getAttachedDocuments(masterClient, {
        subscriptionId: nextSubscr.id,
      }),
      CreditCard.api.getCreditCards(apolloClient, {
        accountId: account.id,
      }),
    ]);

    const isUGolf = golf.network && golf.network === 1;

    const {
      installments,
      membershipPackage: { membership, products },
    } = nextSubscr;

    const productsAmount = products ? products.reduce((acc, p) => acc + p.amount, 0) : 0;

    // soit l'échancier enregistré soit calculé auto
    let paymentSchedule;
    if (installments.length > 0) {
      paymentSchedule = installments.map(({ amount, dueDate }) => ({ amount, dueDate }));
    } else {
      paymentSchedule = Subscription.functions.calculatePaymentSchedule({
        scheduledAmount: membership.amount,
        additionalAmountOnFirstInstallment: productsAmount,
        dayOfMonth: isUGolf ? 5 : 15,
        firstInstallmentDate: isUGolf ? null : moment('2022-01-15'),
        ...nextSubscr,
      });
    }

    return this.setState({
      golf,
      account,
      subscription: {
        ...nextSubscr,
        schedule: paymentSchedule,
      },
      isUGolf,
      prevSubscription: prevSubscr,
      ...(isAlreadyComplete && { activeStep: this.steps.length }),
      documents,
      creditCard: creditCards[0] || null,
    });
  }

  /* */
  renderPackageStep() {
    const { subscription } = this.state;

    const {
      schedule,
      startsAt,
      expiresAt,
      membershipPackage: {
        membership,
        products,
      },
    } = subscription;

    const sumAmount = products
      ? products.reduce((acc, p) => acc + p.amount, membership.amount)
      : membership.amount;

    return (
      <React.Fragment>
        <Grid item md={6}>
          {(products && products.length > 0) ? (
            <>
              <Typography>
                {'Votre formule comprend :'}
              </Typography>
              <Typography>
                {'- Abonnement '}<b>{`${membership.name} `}</b>{`(${i18n.l('currency', membership.amount / 100)})`}
              </Typography>
              {products.map((p, i) => (
                <Typography key={i}>
                  {`- ${p.name} (${i18n.l('currency', p.amount / 100)})`}
                </Typography>
              ))}
            </>
          ) : (
            <Typography>
              <b>{membership.name}</b>
            </Typography>
          )}
          <br />
          <Typography gutterBottom>
            {'A partir du '} <b>{i18n.l('date.formats.default', startsAt)}</b>
            {' jusqu\'au '}<b>{i18n.l('date.formats.default', expiresAt)}</b>
          </Typography>
          <br />
          <Typography gutterBottom>
            {'Montant : '}<b>{i18n.l('currency', sumAmount / 100)}</b>
          </Typography>
          <Typography gutterBottom>
            {'Nombre d\'échéances : '}<b>{schedule.length}</b>
          </Typography>
          <Typography gutterBottom>
            {'Première échéance le '}<b>{i18n.l('date.formats.default', schedule[0].dueDate)}</b>
          </Typography>
        </Grid>
        <Grid item md={6}>
          {this.renderPaymentSchedule()}
        </Grid>
      </React.Fragment>
    );
  }

  /* */
  renderDocumentStep() {
    const {
      approveDocuments,
      consent,
      documents,
      isUGolf,
    } = this.state;
    const { classes } = this.props;

    const AcceptLabel = (
      <Typography>
        {'En cochant cette case, je reconnais avoir pris connaissance des documents suivants, et les accepte : '}<br />
        {documents.map((d, k) => <b key={k}>{`- ${d.name}`}<br /></b>)}
      </Typography>
    );

    // délai de rétractation (period of withdrawal)

    return (
      <Grid item xs={12} sm={12} md={9}>
        <Paper>
          <List disablePadding>
            {documents.map((doc, key) => (
              <ListItem divider key={key}>
                <ListItemText primary={doc.name} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => this.download(doc)}>
                    <FileDownloadIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              value="1"
              className={classes.checkboxCtrl}
              name="approveDocuments"
              color="secondary"
              checked={approveDocuments}
              onChange={e => this.setState({ approveDocuments: e.target.checked })} />
          }
          label={AcceptLabel} />
        {isUGolf && (
          <>
            <br /><br />
            <Typography
              gutterBottom
              color="primary"
              variant="h6">
              {'RGPD (Règlement général sur la protection des données)'}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  value="1"
                  className={classes.checkboxCtrl}
                  name="newsletterGolf"
                  color="secondary"
                  checked={consent.newsletterGolf}
                  onChange={e => this.setState({
                    consent: {
                      ...consent,
                      newsletterGolf: e.target.checked,
                    },
                  })} />
              }
              label={'J\'accepte de recevoir la newsletter du golf.'} />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  value="1"
                  className={classes.checkboxCtrl}
                  name="newsletterUGolf"
                  color="secondary"
                  checked={consent.newsletterUGolf}
                  onChange={e => this.setState({
                    consent: {
                      ...consent,
                      newsletterUGolf: e.target.checked,
                    },
                  })} />
              }
              label={'J\'accepte de recevoir la newsletter du Groupe UGolf.'} />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  value="1"
                  className={classes.checkboxCtrl}
                  name="newsletterLeClubGolf"
                  color="secondary"
                  checked={consent.newsletterLeClubGolf}
                  onChange={e => this.setState({
                    consent: {
                      ...consent,
                      newsletterLeClubGolf: e.target.checked,
                    },
                  })} />
              }
              label={'J\'accepte de recevoir la newsletter de notre partenaire LeClub Golf.'} />
          </>
        )}
      </Grid>
    );
  }

  /* */
  renderPaymentStep() {
    const { creditCard, shouldShowUpdateCB } = this.state;

    const showUpdateCB = () => {
      this.setState({ shouldShowUpdateCB: true });
    };

    const form = (
      <GetCreditCard
        currency={this.currency}
        session3DS={this.session3DS}
        onSubmit={this.handleSubmit}
        authorizationAmount={this.authorizationAmount} />
    );

    return (
      <React.Fragment>
        <Grid item xs={12} sm={9} md={6}>
          {creditCard ? (
            <React.Fragment>
              <Typography
                gutterBottom>
                {'Une carte bancaire est déjà renseignée.'}<br />
                {'Mes prélèvements seront effectués sur la carte ci-dessous.'}
              </Typography>
              <br />
              <CreditCardPreview
                brand={creditCard.brand}
                dueDate={creditCard.dueDate}
                number={creditCard.number} />
              <br />
              <Button variant="outlined" onClick={() => showUpdateCB()}>{'Mettre à jour ma carte bancaire'}</Button>
            </React.Fragment>
          ) : (
            <Typography
              gutterBottom>
              {'Aucune carte bancaire renseignée.'}<br />
              {'Veuillez en ajouter une afin de régler vos échéances automatiquement.'}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={9} md={6}>
          {(shouldShowUpdateCB || !creditCard) && (
            <Paper style={{ padding: 20 }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component='h2'>
                {creditCard ? 'Mettre à jour ma carte bancaire' : 'Ajouter une carte bancaire'}
              </Typography>
              <br />
              {form}
            </Paper>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  /* */
  track = (event) => {
    const { subscription, consent, documents } = this.state;

    let attributes = null;
    if (event === 'documents_approved') {
      attributes = JSON.stringify(documents.map(d => d.id));
    }
    if (event === 'consent_obtained') {
      attributes = JSON.stringify(consent);
    }

    return Subscription.api.trackSubscription(masterClient, {
      event,
      attributes,
      channel: 'web',
      subscriptionId: subscription.id,
    })
      .catch(() => null);
  };

  /* */
  handleNext = events => () => {
    const eventsArr = (typeof events !== 'object')
      ? [events] : events;

    Promise.all(eventsArr.map(this.track))
      .finally(() => this.setState(prevState => ({
        activeStep: prevState.activeStep + 1,
      })));
  };

  /* */
  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  /* */
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { id3D = null } = values;
    const { account } = this.state;

    const uuid = Paybox.functions.createUuidFrom(account.id);

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      success: null,
    });

    return Paybox.api.createSubscriber(apolloClient, {
      id3D,
      uuid,
      reference: account.id,
      amount: this.authorizationAmount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      // Enregistrement cc
      .then(res => CreditCard.api.setCreditCard(apolloClient, {
        id: uuid,
        token: res.PORTEUR,
        accountId: account.id,
        number: CreditCard.functions.anonymizeNumber(creditCard.number),
        ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
      }))
      .catch((err) => {
        if (!err.handled) {
          throw Object.assign(err, {
            handled: true,
            message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
          });
        }
        throw err;
      })
      .then(res => res.setCreditCard)
      .then((res) => {
        this.setState({
          creditCard: res,
          success: 'Votre carte est enregistrée avec succès.',
          shouldShowUpdateCB: false,
        });
        resetForm();
      })
      .catch(err => this.setState({
        status: null,
        error: err.message,
      }))
      .finally(() => {
        setSubmitting(false);
        // this.setState({ buttonLoading: false });
      });
  }

  /* */
  handleComplete = () => {
    const { steps } = this;
    const { subscription, creditCard } = this.state;

    this.setState({
      buttonLoading: true,
      status: 'Nous confirmons votre plan de prélèvement au golf...',
    });

    const params = {
      creditCardId: creditCard.id,
      subscriptionId: subscription.id,
      paymentSchedule: subscription.schedule,
    };

    return Subscription.api.confirmSubscription(masterClient, params)
      .then(({ status, createdAt }) => {
        const newStatuses = [
          { name: status, createdAt },
          ...subscription.statuses,
        ];

        this.setState(prevState => ({
          subscription: {
            ...prevState.subscription,
            statuses: newStatuses,
          },
          activeStep: steps.length,
        }));
      })
      .catch(error => this.setState({ error }))
      .finally(() => this.setState({ buttonLoading: false }));
  }

  /* */
  renderHeader() {
    const {
      golf,
      account,
      activeStep,
      subscription,
      prevSubscription,
    } = this.state;
    const { steps } = this;

    const { membershipPackage = null, expiresAt = null } = prevSubscription || {};

    const lastStatus = subscription.statuses[0];
    const createdAt = moment(lastStatus.createdAt);

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          {golf.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {'Renouvellement'}
        </Typography>
        <br />
        <Typography gutterBottom>
          {'Bonjour '}<b>{`${account.firstname} ${account.lastname}`}</b>{','}
        </Typography>
        {membershipPackage
          && expiresAt
          && activeStep !== steps.length
          && (
            <Typography gutterBottom>
              {'Votre abonnement '}<b>{membershipPackage.membership.name}</b>{' arrivant à échéance le '}<b>
                {i18n.l('date.formats.default', expiresAt)}</b>{', nous vous proposons de renouveler avec la formule ci-dessous.'}
            </Typography>
          )}
        {activeStep === steps.length && (
          <Box
            my={3}
            display="flex"
            alignItems="center"
            flexDirection="column">
            <CheckCircleOutlineIcon fontSize="large" color="secondary" />
            <Typography variant="subtitle1" gutterBottom color="secondary">
              {'Merci d\'avoir renouvelé votre formule.'}
            </Typography>
            <Typography variant="body1" gutterBottom color="secondary">
              {'Fait le '}<b>{createdAt.format('DD/MM/YYYY')}</b>{' à '}<b>{createdAt.format('HH:mm')}</b>
            </Typography>
            <Typography variant="body2" gutterBottom color="secondary">
              {'Nous vous avons transmis par email, à l\'adresse '}<b>{account.email}</b>{', les éléments relatifs à votre souscription.'}
            </Typography>
          </Box>
        )}
      </div>
    );
  }

  /* */
  renderSummaryStep() {
    const { steps } = this;
    const {
      activeStep,
      subscription,
      creditCard,
    } = this.state;

    const {
      schedule,
      startsAt,
      expiresAt,
      membershipPackage: {
        membership,
        products,
      },
    } = subscription;

    const sumAmount = products
      ? products.reduce((acc, p) => acc + p.amount, membership.amount)
      : membership.amount;

    return (
      <React.Fragment>
        {activeStep === (steps.length - 1) && (
          <Grid item md={12}>
            <Box
              mt={4}
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between">
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.handleComplete}>
                  {'Je finalise ma souscription'}
                </Button>
                <Typography variant="caption" gutterBottom color="secondary" style={{
                  display: 'flex',
                  justifyContent: 'end',
                }}>
                  {'Commande avec obligation de paiement'}
                </Typography>
              </div>
            </Box>
          </Grid>
        )}
        <Grid item md={6}>
          <Typography variant="subtitle1" gutterBottom color="secondary">
            {'Formule'}
          </Typography>
          {(products && products.length > 0) ? (
            <>
              <Typography>
                {'- Abonnement '}<b>{`${membership.name} `}</b>{`(${i18n.l('currency', membership.amount / 100)})`}
              </Typography>
              {products.map((p, i) => (
                <Typography key={i}>
                  {`- ${p.name} (${i18n.l('currency', p.amount / 100)})`}
                </Typography>
              ))}
            </>
          ) : (
            <Typography>
              <b>{membership.name}</b>
            </Typography>
          )}
          <br />
          <Typography gutterBottom>
            {'A partir du '} <b>{i18n.l('date.formats.default', startsAt)}</b>
            {' jusqu\'au '}<b>{i18n.l('date.formats.default', expiresAt)}</b>
          </Typography>
          <br />
          <Typography gutterBottom>
            {'Montant : '}<b>{i18n.l('currency', sumAmount / 100)}</b>
          </Typography>
          <Typography gutterBottom>
            {'Nombre d\'échéances : '}<b>{schedule.length}</b>
          </Typography>
          <Typography gutterBottom>
            {'Première échéance le '}<b>{i18n.l('date.formats.default', schedule[0].dueDate)}</b>
          </Typography>
          <br />
          <Typography variant="subtitle1" gutterBottom color="secondary">
            {'Moyen de paiement'}
          </Typography>
          {creditCard && (
            <CreditCardPreview
              brand={creditCard.brand}
              number={creditCard.number}
              dueDate={creditCard.dueDate} />
          )}
          <br />
        </Grid>
        <Grid item md={6}>
          <Typography variant="subtitle1" gutterBottom color="secondary">
            {'Echéancier'}
          </Typography>
          {this.renderPaymentSchedule()}
        </Grid>
      </React.Fragment>
    );
  }

  /* */
  renderStepper() {
    const { steps } = this;
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.stepper}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  /* */
  renderButtons() {
    const { steps } = this;
    const { activeStep, creditCard, approveDocuments } = this.state;

    const texts = [
      'Je valide ma formule',
      'J\'approuve les documents',
      'Je confirme mon moyen de paiment',
      'Je finalise ma souscription',
    ];

    return (
      <Box
        mt={4}
        display="flex"
        flexDirection="row-reverse"
        justifyContent="space-between">
        {activeStep === 0 && (
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleNext('package_confirmed')}>
            {texts[activeStep]}
          </Button>
        )}
        {activeStep === 1 && (
          <Button
            color="primary"
            variant="contained"
            disabled={!approveDocuments}
            onClick={this.handleNext(['documents_approved', 'consent_obtained'])}>
            {texts[activeStep]}
          </Button>
        )}
        {activeStep === 2 && (
          <Button
            color="primary"
            variant="contained"
            disabled={!creditCard}
            onClick={this.handleNext('method_payment_confirmed')}>
            {texts[activeStep]}
          </Button>
        )}
        {activeStep === (steps.length - 1) && (
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleComplete}>
              {texts[activeStep]}
            </Button>
            <Typography variant="caption" gutterBottom color="secondary" style={{
              display: 'flex',
              justifyContent: 'end',
            }}>
              {'Commande avec obligation de paiement'}
            </Typography>
          </div>
        )}
        {activeStep > 0 && activeStep < steps.length && (
          <Button onClick={this.handleBack}>
            {'Précédent'}
          </Button>
        )}
      </Box>
    );
  }

  /* */
  render() {
    const {
      // ux
      error,
      status,
      success,
      buttonLoading,
      screenLoading,
      activeStep,
      // data
      account,
      subscription,
    } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        success={success}
        loading={screenLoading}>
        {account && subscription && (
          <React.Fragment>
            {this.renderHeader()}
            {this.renderStepper()}
            <Grid
              container
              spacing={40}
              justify="center">
              {this.getStepContent(activeStep)}
            </Grid>
            {this.renderButtons()}
            <DialogPaymentIP open={buttonLoading} status={status} />
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

export default withStyles(styles)(MembershipRenew);
