import React from 'react';
import SearchOption from './SearchOption';

const DurationOption = ({
  classes, active, onClick, selectedDuration,
}) => {
  const value = selectedDuration
    ? `${selectedDuration.time} min`
    : '-';

  return (
    <SearchOption
      label="Durée du créneau"
      value={value}
      classes={classes}
      onClick={onClick}
      isExpanded={active}
    />
  );
};

export default DurationOption;
