import _ from 'lodash';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Steps } from 'intro.js-react';
import { withStyles } from '@material-ui/core/styles';
import { App, Directory } from '@aps-management/primapp-common';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Fab,
  Tab,
  List,
  Tabs,
  Paper,
  AppBar,
  Button,
  Dialog,
  Toolbar,
  Checkbox,
  ListItem,
  TextField,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import {
  Add as AddIcon,
  List as ListIcon,
  Star as StarIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Contacts as ContactsIcon,
} from '@material-ui/icons';
import i18n from '_utils/i18n';
import { Wrapper } from '_components/elements';
import apolloClient from '_utils/apolloClient';
import AddContact from '_components/AddContact';


/* */
const styles = theme => ({
  tabs: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  rightButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  flex: { flex: 1 },
  starIcon: { color: '#FDD835' },
  appBar: { position: 'relative' },
  chip: { margin: theme.spacing.unit },
  searchInput: { marginBottom: theme.spacing.unit * 3 },
});

/* */
const memberSteps = [
  {
    element: '#cp-member-title',
    intro: 'Rechercher un membre du golf.',
  },
  {
    element: '#cp-member-search',
    intro: 'Saisir les premières lettres du nom ou du prénom pour retrouver un membre.',
  },
  {
    element: '#cp-contact-title',
    intro: 'Rechercher un joueur extérieur dans ma liste de contacts.',
  },
  {
    element: '#cp-save',
    intro: 'Valider les joueurs sélectionnés.',
  },
  // {
  //   element: '#cp-contact-add',
  //   intro: 'Ajouter un joueur extérieur à ma liste de contacts.',
  // },
];
/* */
const contactSteps = [
  {
    element: '#cp-contact-add',
    intro: 'Ajouter un joueur à ma liste de contacts.',
  },
  {
    element: '#cp-save',
    intro: 'Valider les joueurs sélectionnés.',
  },
];

/* */
const USER_OPTION_NAME = 'choosePartnersHintMessageRead';

/* */
const Loading = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress
      size={32}
      thickness={4}
      color="secondary" />
  </Box>
);

const TAB_MEMBERS = 0;
const TAB_CONTACTS = 1;
const TAB_CORPO = 0;

/* */
class ChoosePartners extends React.Component {
  /* */
  static defaultProps = {
    open: false,
    disableOwner: true,
  };

  /* */
  state = {
    tab: TAB_CONTACTS,
    query: '',
    error: null,
    members: [],
    isMember: false,
    isCorpo: false,
    openDialog: false,
    memberLoading: true,
    stepsEnabled: false,
    noResultFound: false,
    contactLoading: true,
  };

  partners = [];

  steps = [];

  firstLoad = true;

  /* */
  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open === true) {
      this.load();
      this.updateCounter();
    }
  }

  /* */
  handleCompleteSteps = () =>
    App.api.setUserOption(apolloClient, { name: USER_OPTION_NAME, value: Date.now() });

  /* */
  load = () => {
    if (!this.firstLoad) return;
    this.firstLoad = false;

    const { app: { account, user, golf } } = this.props;
    const isMember = App.functions.isMember(account, golf);

    let isCorpo = false;
    if (account.playerTypes && account.playerTypes.length > 0) {
      isCorpo = Boolean(account.playerTypes.filter(pt => pt.golfId === golf.id).length);
    }

    // autoplay steps
    const autoplay = !(user.options && user.options[USER_OPTION_NAME]);

    this.steps = isMember ? memberSteps : contactSteps;

    this.setState({
      stepsEnabled: autoplay,
      isMember,
      isCorpo,
      // eslint-disable-next-line no-nested-ternary
      tab: isCorpo ? TAB_CORPO : isMember ? TAB_MEMBERS : TAB_CONTACTS,
    });

    Directory.api.searchPartners(apolloClient, { golfId: golf.id }, 'no-cache')
      .then(({ partners }) => {
        this.partners = partners;
        this.setState({ members: partners });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ memberLoading: false }));

    if (this.props.contacts === null) {
      Directory.api.getContacts(apolloClient, {}, 'no-cache')
        .then(({ contacts }) => {
          this.props.setContactsList(contacts);
        })
        .catch(e => this.setState({ error: e.message }))
        .finally(() => this.setState({ contactLoading: false }));
    } else {
      this.setState({ contactLoading: false });
    }
  }

  /* */
  updateCounter() {
    const { partners } = this.props;

    const total = partners.length;
    const counter = partners.reduce((acc, p) => ((p.type !== 'anonymous') ? acc + 1 : acc), 0);

    if (counter === total) {
      this.props.onClose();
    } else {
      this.setState({ total, counter });
    }
  }

  /* */
  handleSearch = (e) => {
    this.setState({ query: e.target.value });
    this.searchMembers();
  }

  /* */
  searchMembers = _.debounce(() => {
    const { partners } = this;
    const { query, tab, isCorpo } = this.state;
    const { golf } = this.props.app;

    const searchCorpo = tab === TAB_CORPO && isCorpo;

    if (query.trim().length >= 3) {
      this.setState({
        error: null,
        memberLoading: true,
      });
      Directory.api.searchMembers(apolloClient, {
        name: query,
        fullData: 1,
        golfId: golf.id,
        corpo: searchCorpo,
      }, 'no-cache')
        .then(({ members }) => this.setState({
          members,
          noResultFound: (members.length === 0),
        }))
        .catch(e => this.setState({ error: e.message }))
        .finally(() => this.setState({ memberLoading: false }));
    } else if (query.length === 0 && partners.length > 0) {
      this.setState({ members: partners });
    }
  }, 1000);

  /* */
  handleClose = () => this.props.onClose();

  /* */
  handleTabChange = (event, tab) => this.setState({ tab });

  /* */
  handleAddContact = () => {
    this.setState(state => ({ openDialog: !state.openDialog }));
  };

  /* */
  handleSelect = async (item, type) => {
    // Rechercher la première position non sélectionnée (anonymous)
    const index = this.props.partners.findIndex(p => p.type === 'anonymous');
    if (index === -1) {
      this.props.onClose();
      return;
    }
    
    const player = type === 'member'
      ? { ...item, isMember: true }
      : { ...item, isMember: false, reference: 'UNKNOWN' };
    
    await this.props.replacePartner({ ...player, type }, index);
    this.updateCounter();
  }

  /* */
  handleUnselect = async (i, type) => {
    const {
      contacts,
      partners,
    } = this.props;
    const { members } = this.state;

    const index = (type === 'member')
      ? partners.findIndex(p => p.reference === members[i].reference)
      : partners.findIndex(p => p.uid === contacts[i].uid);

    await this.props.replacePartner({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    }, index);

    this.updateCounter();
  }

  /* */
  renderMemberList() {
    const { members, memberLoading, noResultFound } = this.state;

    if (memberLoading) return <Loading />;

    if (noResultFound) {
      return (
        <Typography weight="light">{'Aucun résultat.'}</Typography>
      );
    }

    if (members.length > 0) {
      return (
        <Paper>
          <List style={{
            maxHeight: 300,
            overflow: 'auto',
          }} disablePadding>{members.map(this.renderMember)}</List>
        </Paper>
      );
    }

    return null;
  }

  // dupliquer
  /* */
  renderMember = (m, i) => {
    const { partners: favorites } = this;
    const { classes, partners, disableOwner } = this.props;
    // (!) this.props.partners =/= this.partners (!)

    const refs = partners.filter(p => p.reference !== 'UNKNOWN').map(p => p.reference);
    const checked = refs.includes(m.reference);

    const isOwner = partners.find(p => m.reference === p.reference && p.type === 'owner');
    const disabled = isOwner && disableOwner;

    const isFavorite = favorites.find(p => m.reference === p.reference);

    const handleClick = () => this.handleSelect(m, 'member');

    return (
      <ListItem
        button
        key={i}
        disableRipple
        disabled={disabled}
        onClick={handleClick}
        classes={{ root: classes.listItem }}>
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked}
          disabled={disabled} />
        <ListItemText primary={`${m.firstname} ${m.lastname}`} />
        {isFavorite && (
          <ListItemIcon className={classes.starIcon}>
            <StarIcon />
          </ListItemIcon>
        )}
      </ListItem>
    );
  }

  /* */
  renderContactList() {
    const { contacts } = this.props;
    const { contactLoading } = this.state;

    if (contactLoading) return <Loading />;

    if (contacts && contacts.length > 0) {
      return (
        <Paper>
          <List disablePadding>{contacts.map(this.renderContact)}</List>
        </Paper>
      );
    }

    return (
      <Typography weight="light">
        {'Aucun contact dans votre répertoire.'}
      </Typography>
    );
  }

  /* */
  renderContact = (c, i) => {
    const { classes, partners } = this.props;

    const uids = partners.filter(p => !!p.uid).map(p => p.uid);
    const checked = uids.includes(c.uid);

    const handleClick = () => this.handleSelect(c, 'contact');

    return (
      <ListItem
        button
        disableRipple
        key={i}
        onClick={handleClick}
        classes={{ root: classes.listItem }}>
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked} />
        <ListItemText primary={`${c.firstname} ${c.lastname}`} />
      </ListItem>
    );
  };

  /* */
  renderContactTab() {
    const { isMember, total, counter } = this.state;
    const title = isMember ? 'Choisir un joueur extérieur' : 'Choisir un joueur';

    return (
      <Fragment>
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Typography align="center" variant="h5">
            {title}
          </Typography>
          <Fab
            color="secondary"
            id="cp-contact-add"
            aria-label="Ajouter un contact"
            onClick={this.handleAddContact}>
            <AddIcon />
          </Fab>
        </Box>
        <Typography align="center" variant="caption" paragraph>
          {`Veuillez sélectionner ${total - counter} partenaire(s).`}
        </Typography>
        {this.renderContactList()}
      </Fragment>
    );
  }

  /* */
  renderMemberTab() {
    const { classes } = this.props;
    const { query, counter, total } = this.state;

    return (
      <div>
        <Typography align="center" variant="h5" paragraph>
          {'Rechercher un membre'}
        </Typography>
        <TextField
          fullWidth
          value={query}
          variant="outlined"
          placeholder="Nom ou prénom"
          onChange={this.handleSearch}
          className={classes.searchInput}
          InputProps={{
            id: 'cp-member-search',
            endAdornment: <SearchIcon />,
          }} />
        <Typography align="center" variant="caption" paragraph>
          {`Veuillez sélectionner ${total - counter} partenaire(s).`}
        </Typography>
        {this.renderMemberList()}
      </div>
    );
  }

  /* */
  renderIntro() {
    const { stepsEnabled } = this.state;
    // Todo component NStepGuide with IntroJS

    return (
      <Steps
        steps={this.steps}
        onExit={() => this.setState({ stepsEnabled: false })}
        onComplete={this.handleCompleteSteps}
        enabled={stepsEnabled}
        options={{
          hidePrev: true,
          hideNext: true,
          doneLabel: 'OK',
          skipLabel: 'Passer',
          prevLabel: '<',
          nextLabel: '>',
        }}
        initialStep={0} />
    );
  }

  /* */
  render() {
    const {
      tab,
      isMember,
      isCorpo,
      openDialog,
    } = this.state;
    const {
      open,
      classes,
      wording,
    } = this.props;

    return (
      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          classes={{ paperFullScreen: classes.dialog }}>
          <AppBar color="primary" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                className={classes.rightButton}
                onClick={this.handleClose}
                aria-label="Fermer">
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.flex}>
                {'Vos partenaires'}
              </Typography>
              <Button color="inherit" onClick={this.handleClose} id="cp-save">
                {'Enregistrer'}
              </Button>
            </Toolbar>
          </AppBar>
          {isCorpo && !isMember && (
            <Tabs
              value={tab}
              variant="fullWidth"
              className={classes.tabs}
              onChange={this.handleTabChange}>
              <Tab id='cp-member-title' icon={<ListIcon />} label={i18n.t('booking.titles.list_corpo')} />
              <Tab id='cp-contact-title' icon={<ContactsIcon />} label={i18n.t('booking.titles.my_contacts')} />
            </Tabs>
          )}
          {!isCorpo && isMember && (
            <Tabs
              value={tab}
              variant="fullWidth"
              className={classes.tabs}
              onChange={this.handleTabChange}>
              <Tab id='cp-member-title' icon={<ListIcon />} label={i18n.t(`booking.titles.list_members_${wording.member}`)} />
              <Tab id='cp-contact-title' icon={<ContactsIcon />} label={i18n.t('booking.titles.my_contacts')} />
            </Tabs>
          )}
          <Wrapper layout="fixed">
            {isMember && (tab === TAB_MEMBERS) && this.renderMemberTab()}
            {isCorpo && (tab === TAB_CORPO) && this.renderMemberTab()}
            {tab === TAB_CONTACTS && this.renderContactTab()}
          </Wrapper>
          {this.renderIntro()}
        </Dialog>
        <AddContact open={openDialog} onClose={this.handleAddContact} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  app,
  directory: { contacts },
}) => ({
  app,
  contacts,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(ChoosePartners);

export default connect(mapStateToProps, Directory.actions)(StyledComponent);
