import { initializeApp } from 'firebase/app';
import {
  getIdToken, onAuthStateChanged,
  signOut, getAuth,
} from 'firebase/auth';

import {
  getDatabase, ref, off, onValue,
} from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { App } from '@aps-management/primapp-common';
import store from '_config/store';

/* */
export default class Firebase {
  /* */
  static app = initializeApp(global.firebase);

  /* */
  static userRef = null;

  /* */
  static initialized = false;

  /* */
  static onAuthStateChanged = null;

  /* */
  static refreshTokenTimer = null;

  /* */
  static auth = getAuth(this.app);

  /* */
  static database = getDatabase(this.app);

  /* */
  static storage = getStorage(this.app, global.firebase.storageBucket);

  /* */
  static init() {
    if (!this.initialized) {
      initializeApp(global.firebase);
    }
    this.initialized = true;
  }

  /* */
  static isAuthenticated = () => !!store.getState().app.user;

  /* */
  static logout = () => signOut(this.auth);

  /* When user value change in databse (also first data fetch) */
  static snap(snapshot) {
    if (snapshot.exists()) {
      const data = snapshot.val();
      store.dispatch(App.actions.setUser({ uid: snapshot.key, ...data }));
    } else {
      store.dispatch(App.actions.logout());
    }
  }

  /* */
  static getIdToken(user, refresh = false) {
    return getIdToken(user, true)
      .then((token) => {
        store.dispatch(App.actions.autoLogin(user.email, token, refresh));
        // listen to user update
        if (!refresh) {
          if (this.userRef) off(this.userRef, this.snap);
          this.userRef = ref(this.database, `users/${user.uid}`);
          onValue(this.userRef, this.snap);
        }
      })
      .catch((err) => {
        store.dispatch(App.actions.logout(err));
      });
  }

  /* */
  static connection = () => {
    store.dispatch(App.actions.connect());
    this.onAuthStateChanged = onAuthStateChanged(this.auth,
      // auth state changed
      (user) => {
        if (user) {
          // Token expires every hour, force refresh
          if (this.refreshTokenTimer) clearInterval(this.refreshTokenTimer);
          this.refreshTokenTimer = setInterval(
            () => this.getIdToken(user, true),
            50 * 60 * 1000, // refresh every 50 minutes
          );
          this.getIdToken(user);
        } else {
          // not logged in
          if (this.refreshTokenTimer) clearInterval(this.refreshTokenTimer);
          this.refreshTokenTimer = null;
          store.dispatch(App.actions.logout());
        }
      },
      // Auth state change error
      (err) => {
        this.disconnect(err);
      },
      // Auth state change completed
      () => {
        this.disconnect();
      });
  }

  /* When network connection disconnect */
  static disconnect = (err) => {
    // Stop watching user data
    if (this.userRef) off(this.userRef, this.snap);
    this.userRef = null;
    // Stop reconnecting forcefully
    if (this.refreshTokenTimer) clearInterval(this.refreshTokenTimer);
    this.refreshTokenTimer = null;
    // Stop listening auth state change
    if (this.onAuthStateChanged) this.onAuthStateChanged();
    this.onAuthStateChanged = null;
    // Reset store
    store.dispatch(App.actions.logout(err));
  }
}
