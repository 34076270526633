import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { useContextSelector } from '@fluentui/react-context-selector';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { Activity, App, Booking } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import RenderButton from './RenderButton';
import DateOption from '../options/DateOption';
import DurationOption from '../options/DurationOption';
import PlayersOption from '../options/PlayersOption';
import SetDate from '../setters/SetDate';
import SetPlayers from '../setters/SetPlayers';
import ActivityContext from '../../contexts/ActivityContext';
import SetDuration from '../setters/SetDuration';

const OptionsPanel = ({
  width,
  classes,
  history,
  account,
  date,
  players,
  golf,
}) => {
  const {
    course,
    activeOption,
    setActiveOption,
    selectedDuration,
    setShowDisponibilities,
    selectedActivity,
  } = useContextSelector(ActivityContext, (c) => c);
  const isMember = App.functions.isMember(account, golf);
  if (isWidthUp('md', width)) {
    return (
        <Grid container spacing={24}>
          <Grid item md={6}>
            <Box m={1} mb={3}>
            <Typography
              paragraph
              component="p"
              variant="h6">
              {'Choisissez vos préférences'}
            </Typography>
            </Box>
            <DateOption
              date={date}
              classes={classes}
              active={activeOption === 0}
              onClick={() => setActiveOption(0)}
            />
            <DurationOption
              classes={classes}
              active={activeOption === 4}
              onClick={() => setActiveOption(4)}
              selectedDuration={selectedDuration}
            />
            <PlayersOption
              players={players}
              classes={classes}
              active={activeOption === 2}
              onClick={() => setActiveOption(2)}
              course={course}
            />
            <RenderButton
              course={course}
              classes={classes}
              history={history}
              account={account}
              setShowDisponibilities={setShowDisponibilities}
            />
          </Grid>
          <Grid item md={6}>
            {activeOption === 0 && <SetDate maxDays={isMember
              ? selectedActivity?.dayMember
              : selectedActivity?.dayGuest
            } />}
            {activeOption === 2 && <SetPlayers />}
            {activeOption === 4 && (
              <SetDuration
              />
            )}
          </Grid>
        </Grid>
    );
  }
  return (
    <>
      <DateOption
        date={date}
        classes={classes}
        active={activeOption === 0}
        onClick={() => setActiveOption(0)}
      />
      {activeOption === 0 && <SetDate />}
      <DurationOption
        classes={classes}
        active={activeOption === 4}
        onClick={() => setActiveOption(4)}
        selectedDuration={selectedDuration}
      />
      {activeOption === 4 && (
        <SetDuration
          onDurationSelect={() => null}
        />
      )}
      <PlayersOption
        players={players}
        classes={classes}
        active={activeOption === 2}
        onClick={() => setActiveOption(2)}
        course={course}
      />
      {activeOption === 2 && <SetPlayers />}
      <RenderButton
        course={course}
        classes={classes}
        history={history}
      />
    </>
  );
};

const mapStateToProps = ({
  app, bookingData, bookingSearch, activityData,
}) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
  search: bookingSearch,
  activityData,
});

const combinedActions = { ...Booking.actions, ...Activity.actions };

export default connect(
  mapStateToProps,
  combinedActions,
)(OptionsPanel);
