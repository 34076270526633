import 'moment/locale/fr';
import React from 'react';
import { connect } from 'react-redux';
import { Booking } from '@aps-management/primapp-common';
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  BasePicker,
  Calendar,
  MuiPickersUtilsProvider,
} from 'material-ui-pickers';
import {
  KeyboardArrowLeft as LeftIcon,
  KeyboardArrowRight as RightIcon,
} from '@material-ui/icons';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

/* */
const styles = (theme) => ({
  picker: {
    overflow: 'hidden',
    marginTop: theme.spacing.unit * 3,
    // marginLeft: 'auto',
  },
});

/* */
class SetDate extends React.Component {
  render() {
    const {
      classes, setDate, search: { date }, maxDays,
    } = this.props;
    const current = moment();
    const maxDate = moment().add(maxDays * 1000, 'days');
    const selectedDate = moment(date);
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Box m={1} mb={3}>
          <Typography
            paragraph
            component="p"
            variant="h6">
            {'Quand souhaitez-vous jouer ?'}
          </Typography>
          <Paper className={classes.picker}>
            <BasePicker
              onChange={setDate}
              value={selectedDate}>
              {({ date: currentDate }) => (
                <Calendar
                  date={currentDate}
                  disablePast={true}
                  leftArrowIcon={<LeftIcon />}
                  maxDate={maxDate}
                  onChange={setDate}
                  rightArrowIcon={<RightIcon />}
                  shouldDisableDate={(day) => moment(day).add(1, 'day').isBefore(current)} />
              )}
            </BasePicker>
          </Paper>
        </Box>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = ({ bookingData, bookingSearch }) =>
  ({ data: bookingData, search: bookingSearch });

const StyledComponent = withStyles(styles)(SetDate);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
