import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { createContext } from '@fluentui/react-context-selector';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  helpers, App, Booking, CACHE_TIMES,
} from '@aps-management/primapp-common';
import masterClient from '_utils/masterClient';
import { useQuery } from '@tanstack/react-query';

// Importer actions, reducer et API depuis le module Activity
import * as activityActions from '@aps-management/primapp-common/src/features/Activity/actions';
import * as activityApi from '@aps-management/primapp-common/src/features/Activity/api';
import {
  generateColorFromString,
  getContrastColor,
} from '../utils/colorUtils';

const {
  setActiveOption,
  setSelectedDuration,
  setSelectedActivity,
  setShowDisponibilities,
  setCourse,
  setTypes,
  setTypesFilters,
  setError,
  resetState,
} = activityActions;

const { error: logError } = console;

// État par défaut du contexte (complété par l’état Redux)
const defaultActivityContext = {
  error: null,
  loading: true,
  activeOption: 0,
  selectedDuration: null,
  showDisponibilities: false,
  course: null,
  types: [],
  activities: [],
  rawSlots: [],
  selectedSlot: null,
  selectedActivity: null,
  setError: () => {},
};

const ActivityContext = createContext(defaultActivityContext);

// Hook pour gérer l’URL et ses paramètres
const useActivityRouting = () => {
  const location = useLocation();
  const history = useHistory();
  const pathname = location?.pathname;
  const search = location?.search;
  const isActive = useMemo(() => String(pathname).includes('activity'), [pathname]);
  // Calculer isPublic dynamiquement à partir du pathname
  const isPublic = useMemo(() => pathname === '/public-activity', [pathname]);
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const activityIdFromUrl = useMemo(() => parseInt(params.get('activity'), 10), [params]);

  return { location, history, isActive, isPublic, params, activityIdFromUrl };
};

// Hook pour les actions Redux
const useActivityActions = () => {
  const dispatch = useDispatch();
  return {
    setActivity: useCallback(
      (activity) => dispatch(Booking.actions.setActivity(activity)),
      [dispatch],
    ),
    setDate: useCallback(
      (date) => dispatch(Booking.actions.setDate(date)),
      [dispatch],
    ),
    reset: useCallback(() => dispatch(Booking.actions.reset()), [dispatch]),
    addPlayer: useCallback(
      (player) => dispatch(Booking.actions.addPlayer(player)),
      [dispatch],
    ),
    setCoursesList: useCallback(
      (list) => dispatch(Booking.actions.setCoursesList(list)),
      [dispatch],
    ),
    // Cette action mettra à jour activityData.selectedActivity dans le store
    setSelectedActivity: useCallback(
      (activity) => dispatch(setSelectedActivity(activity)),
      [dispatch],
    ),
  };
};

export const ActivityProvider = ({ children }) => {
  const { location, history, isActive, isPublic, activityIdFromUrl } = useActivityRouting();

  // Sélection depuis le store Redux
  const account = useSelector((state) => state.app.account, shallowEqual);
  const golf = useSelector((state) => state.app.golf, shallowEqual);
  const search = useSelector((state) => state.bookingSearch, shallowEqual);
  const reduxError = useSelector((state) => state.app.error, shallowEqual);
  const activityData = useSelector((state) => state.activityData, shallowEqual);

  const dispatch = useDispatch();
  const {
    setDate,
    setActivity,
    reset,
    addPlayer,
    setCoursesList,
    setSelectedActivity: reduxSetSelectedActivity,
  } = useActivityActions();

  // Si besoin, on peut gérer localement des données qui ne font pas partie du store
  const [selectedSlot, setSelectedSlot] = useState(null);
  // Vérifier si location.state contient from === 'public-activity'
  const isFromPublic = location.state?.from === 'public-activity';

  // Si en mode public, on définit activeOption à 2 lors du montage
  useEffect(() => {
    if (isFromPublic) {
      const BookingInfo = location.state?.bookingInfo;
      console.log('BookingInfo', BookingInfo);
      if (BookingInfo?.activity) {
        dispatch(reduxSetSelectedActivity(BookingInfo?.activity));
      }
      dispatch(setDate(BookingInfo?.startsAt));
      dispatch(setActiveOption(2));
      dispatch(setSelectedDuration(BookingInfo?.duration || null));
    }
  }, [isFromPublic, location.state, dispatch, reduxSetSelectedActivity, setDate]);
  // Pour d'autres valeurs par défaut en mode public (exemple : selectedSlot, bookingInfo, players)
  const defaultPublicValues = useMemo(() => {
    if (isFromPublic) {
      return {
        selectedSlot: location.state.selectedSlot || null,
        bookingInfo: location.state.bookingInfo || null,
        players: location.state.players || [],
      };
    }
    return {};
  }, [isFromPublic, location.state]);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => { isMounted.current = false; };
  }, []);

  // Réinitialisation du slice activityData lors d'un changement de route
  useEffect(() => {
    dispatch(resetState({ isPublic }));
  }, [isPublic, dispatch]);

  // Gestion des types d'activité basée sur activityData.selectedActivity
  useEffect(() => {
    const selectedActivity = activityData.selectedActivity;
    if (selectedActivity) {
      if (selectedActivity.type?.length > 0) {
        const computedTypes = selectedActivity.type.map((t) => ({
          id: t.typeId,
          firstName: t.name,
          bgColor: t.typeColor,
          textColor: '#ffffff',
        }));
        dispatch(setTypes(computedTypes));
        dispatch(setTypesFilters(computedTypes.map((t) => t.id)));
        dispatch(setSelectedActivity(selectedActivity));
      } else {
        const defaultType = {
          id: 0,
          firstName: '',
          bgColor: generateColorFromString('default'),
          textColor: getContrastColor(generateColorFromString('default')),
        };
        dispatch(setTypes([defaultType]));
        dispatch(setTypesFilters([0]));
        dispatch(setSelectedActivity(selectedActivity));
      }
    }
  }, [activityData.selectedActivity, dispatch]);

  // Paramètres de la requête basés sur activityData.selectedActivity et autres
  const queryParams = useMemo(() => {
    if (
      !activityData.selectedDuration ||
      !activityData.showDisponibilities ||
      !golf?.id ||
      !activityData.selectedActivity?.id
    ) {
      return null;
    }
    return {
      golfId: search.golf?.id || golf.id,
      date: search.date || new Date().toISOString(),
      activity: activityData.selectedActivity.id,
      time: activityData.selectedDuration.time,
      players: (search.players || []).map((pl) => ({
        tid: pl.reference,
        firstname: pl.firstname,
        lastname: pl.lastname,
        email: pl.email,
      })),
    };
  }, [search, golf, activityData.selectedActivity, activityData.showDisponibilities, activityData.selectedDuration]);

  // Chargement des activités via React Query
  const { data: activities = [], isFetching } = useQuery({
    queryKey: ['activities', golf?.id],
    queryFn: () => activityApi.getActivities(masterClient, { golfId: golf.id }),
    enabled: isActive && !!golf?.id,
    staleTime: CACHE_TIMES.ACTIVITIES.STALE_TIME,
    cacheTime: CACHE_TIMES.ACTIVITIES.CACHE_TIME,
    refetchOnWindowFocus: false,
    retry: 0,
    onError: (error) => { dispatch(setError(`Erreur lors du chargement des activités: ${error.message}`)); },
  });

  const { data: rawSlots = [], isFetching: rawSlotsIsFetching } = useQuery({
    queryKey: ['slots', queryParams],
    queryFn: () => activityApi.getRawSlots(masterClient, queryParams),
    enabled:
      isActive &&
      !!golf?.id &&
      !!activityData.selectedActivity?.id &&
      !!activityData.showDisponibilities &&
      !!activityData.selectedDuration,
    staleTime: CACHE_TIMES.SLOTS.STALE_TIME,
    cacheTime: CACHE_TIMES.SLOTS.CACHE_TIME,
    refetchOnWindowFocus: false,
    retry: 0,
    onError: (error) => { dispatch(setError(`Erreur lors du chargement des créneaux: ${error.message}`)); },
  });

  const match = useMemo(
    () => App.functions.match(account, golf) || { isMember: false, reference: 'UNKNOWN' },
    [account, golf],
  );

  // Si aucune activité n'est sélectionnée, on en choisit une par défaut
  useEffect(() => {
    if (activities.length > 0 && (!activityData.selectedActivity || !activities.some((act) => act.id === activityData.selectedActivity.id))) {
      dispatch(reduxSetSelectedActivity(activities[0]));
    }
  }, [activities, activityData.selectedActivity, dispatch, reduxSetSelectedActivity]);

  // Mise à jour de selectedActivity en fonction de l'URL
  useEffect(() => {
    if (activityIdFromUrl) {
      const matchingActivity = activities.find((act) => act.id === activityIdFromUrl);
      if (matchingActivity) {
        dispatch(reduxSetSelectedActivity(matchingActivity));
      }
    }
  }, [activities, activityIdFromUrl, dispatch, reduxSetSelectedActivity]);

  // Gestion des durées et ressources pour l'activité sélectionnée
  useEffect(() => {
    if (!isFromPublic) {
      if (activityData.selectedActivity && isMounted.current) {
        if (activityData.selectedActivity.abandon === true) {
          dispatch(setSelectedDuration(null));
          dispatch(setCourse(null));
          return;
        }
        const sortedHourPrices = [...(activityData.selectedActivity.hourPrice || [])].sort((a, b) => a.time - b.time);
        const firstHourPrice = sortedHourPrices[0] || null;
        const firstResource = (activityData.selectedActivity.resources || [])[0] || null;
        dispatch(setSelectedDuration(firstHourPrice));
        dispatch(setCourse(firstResource));
        dispatch(setError(null));
      }
    }  
  }, [activityData.selectedActivity, dispatch, isFromPublic]);

  // Fonction de chargement
  const load = useCallback(async () => {
    if (!activityData.selectedActivity || !activities.length || !isMounted.current) return;

    if (isFromPublic) {
      dispatch(setActiveOption(0));
    }

    try {
      const currentAct = activities.find((act) => act.id === activityData.selectedActivity.id);
      if (currentAct?.abandon === true) {
        dispatch(setError("Cette activité n'est pas disponible"));
        dispatch(setCourse(null));
        return;
      }

      if (!isMounted.current) return;

      setActivity(activityData.selectedActivity);
      reset();

      const playerData = account
        ? {
            email: account.email || '',
            phone: account.phone || '',
            lastname: account.lastname?.toUpperCase() || '',
            firstname: helpers.string.ucfirst(account.firstname || ''),
            type: 'owner',
            isMember: match.isMember,
            reference: match.reference,
          }
        : {
            email: null,
            phone: null,
            lastname: null,
            firstname: null,
            isMember: false,
            type: 'anonymous',
            reference: 'UNKNOWN',
          };

      addPlayer(playerData);

      const coursesFromJson = currentAct?.resources || [];
      setCoursesList(coursesFromJson);

      if (coursesFromJson.length > 0 && !activityData.course) {
        dispatch(setCourse(coursesFromJson[0]));
      }

      dispatch(setError(null));
    } catch (err) {
      logError(err);
      if (isMounted.current) {
        dispatch(setError(`Erreur lors du chargement: ${err.message}`));
      }
    }
  }, [
    activities,
    activityData.selectedActivity,
    account,
    match,
    setActivity,
    addPlayer,
    setCoursesList,
    reset,
    activityData.course,
    dispatch,
    isFromPublic,
  ]);

  useEffect(() => {
    if (account) load();
  }, [account, load]);

  // Changement d'activité
  const changeActivity = useCallback(
    (newActivity) => {
      if (!newActivity) {
        dispatch(setError('Activité invalide'));
        return;
      }
      const searchParams = new URLSearchParams(location.search);
      dispatch(reduxSetSelectedActivity(newActivity));
      if (isPublic) {
        searchParams.set('activity', newActivity.id);
        history.push(`${location.pathname}?${searchParams.toString()}`);
      } else {
        dispatch(setShowDisponibilities(false));
      }
    },
    [isPublic, location, history, dispatch, reduxSetSelectedActivity],
  );

  const updateSelectedSlot = (newSlot) => {
    setSelectedSlot(newSlot);
  };

  // Valeur du contexte composée de l'état Redux et de l'état local (selectedSlot)
  const value = useMemo(() => ({
    ...activityData,
    selectedSlot: isFromPublic ? defaultPublicValues.selectedSlot : selectedSlot,
    activeOption: isFromPublic ? 2 : activityData.activeOption,
    loading: isFetching,
    changeActivity,
    activities,
    rawSlots,
    rawSlotsIsFetching,
    isPublic,
    showDisponibilities: activityData.showDisponibilities,
    setActiveOption: (val) => dispatch(setActiveOption(val)),
    setSelectedDuration: (val) => dispatch(setSelectedDuration(val)),
    setShowDisponibilities: (val) => dispatch(setShowDisponibilities(val)),
    setTypesFilters: (val) => dispatch(setTypesFilters(val)),
    load,
    error: reduxError || activityData.error,
    setError: (e) => dispatch(setError(e)),
    updateSelectedSlot,
  }), [
    activityData,
    selectedSlot,
    isFetching,
    changeActivity,
    activities,
    rawSlots,
    rawSlotsIsFetching,
    isPublic,
    load,
    reduxError,
    dispatch,
    isFromPublic,
    defaultPublicValues,
  ]);

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityContext;
