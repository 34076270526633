import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import masterClient from "_utils/masterClient";
import { useHistory } from "react-router-dom";
import {
  colors,
  Paper,
  Radio,
  Chip,
  Grid,
  Tooltip,
  Avatar,
  ButtonBase,
  Typography,
  IconButton,
  RadioGroup,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import { unstable_Box as Box } from "@material-ui/core/Box";
import {
  Lock as LockIcon,
  CheckTwoTone as CheckIcon,
  LockOpen as LockOpenIcon,
  AccessTime as AccessTimeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  InfoOutlined as InfoOutlinedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  GolfCourse as GolfCourseIcon,
} from "@material-ui/icons";
import {
  MdOutlineAccountCircle,
  MdOutlineEuro,
  MdOutlineBook,
  MdOutlineSchool,
  MdOutlineFilterAlt,
  MdOutlineDeveloperBoard,
} from "react-icons/md";
import { Academy } from "@aps-management/primapp-common";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { CalendarDay } from "_components/elements";

/* */
const styles = (theme) => ({
  trnName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
  },
  loaderContainer: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: `${theme.spacing.unit * 4}px 0`,
  },
  headerContainer: {
    position: "relative",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  hints: {
    position: "absolute",
    top: -5,
    right: 0,
    zIndex: 2,
  },
  proContent: {
    position: "absolute",
    top: -5,
    left: 0,
    zIndex: 2,
  },
  price: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(224, 225, 215, 1)",
    cursor: "pointer",
  },
  slots: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(132, 189, 227, 1)",
    cursor: "pointer",
  },
  players: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(61, 195, 77, 1)",
    cursor: "pointer",
  },
  pro: {
    marginRight: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    cursor: "pointer",
  },
  item: {
    height: "100%",
    overflow: "hidden",
    position: "relative",
    padding: theme.spacing.unit * 2,
  },
  select: { minWidth: 120 },
  oHidden: { overflow: "hidden" },
  itemContainer: {
    display: "block",
  },
  registrationWarning: { color: "#FF7E07" },
  inProgress: { color: "#3fb917" },
  registrationClose: { color: colors.red[500] },
  full: { color: colors.red[500] },
  registrationOpen: { color: colors.green[500] },
  lifecycle: { color: theme.palette.text.primary },
  titleButton: {
    marginLeft: theme.spacing.unit * 2,
  },
  optionContainer: {
    alignItems: "center",
    color: theme.palette.grey[500],
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  optionContent: {
    flex: 1,
    marginRight: theme.spacing.unit * 2,
    overflow: "hidden",
  },
  optionValue: {
    color: theme.palette.text.secondary,
    lineHeight: "inherit",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
    padding: 4,
    marginBottom: 15,
  },
  filterInterContainer: {
    display: "flex",
    backgroundColor: "white",
    flex: 1,
    padding: 2,
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
  },
  filterInterIconContainer: {
    display: "flex",
    width: 28,
    marginRight: 16,
    marginLeft: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  filterInterContentContainer: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    padding: 8,
  },
  filterInterContentContainerItemActive: {
    display: "flex",
    padding: 2,
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    marginRight: 4,
    backgroundColor: theme.palette.common.white,
  },
  filterInterContentContainerItemInactive: {
    display: "flex",
    padding: 2,
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    marginRight: 4,
    backgroundColor: "gray",
  },
  filterInterContentContainerItemAvatar: {
    textShadow: `
      1px 1px 0 #00000033,
      -1px 1px 0 #00000033,
      -1px -1px 0 #00000033,
      1px -1px 0 #00000033
        `,
    fontWeight: 800,
    fontSize: 12,
    borderRadius: 5,
    width: 32,
    height: 32,
  },
  radioGroup: {
    border: 0,
    borderRadius: 3,
    height: 48,
    padding: "0 30px",
  },
  radioButton: {
    margin: 0,
    fontSize: 12,
    height: 36,
    minWidth: 64,
    padding: 4,
    color: theme.palette.common.white,
  },
  radioButtonRoot: {
    margin: 4,
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    userSelect: "none",
  },
  radioButtonLabelActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  radioButtonLabelInactive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  radioButtonActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  radioButtonInactive: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  radioButtonContent: {
    display: "none",
  },
  timeContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
});

/* */
const goToView = (lesson, history) => {
  let screen = `/academy/${lesson.id}`;

  if (lesson.lifecycle === "results") screen = `/academy/${lesson.id}/results`;
  if (lesson.lifecycle === "teetime")
    screen = `/academy/${lesson.id}/startlist`;

  return screen ? history.push(screen) : null;
};

/* */
const getColorStatus = (status) => {
  switch (status) {
    case 1:
    case 2:
      return "registrationOpen";
    case 3:
      return "registrationWarning";
    default:
      return "registrationClose";
  }
};
const getminMaxColor = (min, max) => {
  switch (true) {
    case min >= max:
      return "rgba(217, 89, 89, 1)";
    case min >= max / 2:
      return "rgba(239, 116, 39, 1)";
    default:
      return "rgba(61, 195, 77, 1)";
  }
};
/* */
const RenderHeader = ({ calendar, setCalendar }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="space-evenly"
      paddingBottom={2}
    >
      <IconButton
        onClick={() => setCalendar(calendar.clone().subtract(1, "month"))}
        color="primary"
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant="h6">
        {calendar?.format("MMMM YYYY").toUpperCase()}
      </Typography>
      <IconButton
        onClick={() => setCalendar(calendar.clone().add(1, "month"))}
        color="primary"
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

const useFilters = ({
  classes,
  coachs,
  coachsFilters,
  setCoachsFilters,
  skillLevels,
  skillLevelsFilters,
  setSkillLevelsFilters,
}) => {
  if (!coachsFilters || coachs.length === 0 || skillLevels.length === 0)
    return null;
  return (
    <Box>
      <Box className={classes.timeContainer}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={"value"}
          className={classes.radioGroup}
          onChange={() => null}
        >
          {skillLevels?.map((d, i) => (
            <FormControlLabel
              key={`skill-level-${d.id}-${i}`}
              className={classNames(classes.radioButton, {
                [classes.radioButtonActive]: skillLevelsFilters === d.id,
                [classes.radioButtonInactive]: skillLevelsFilters !== d.id,
              })}
              classes={{
                root: classes.radioButtonRoot,
                label: classNames(classes.radioButton, {
                  [classes.radioButtonLabelActive]: skillLevelsFilters === d.id,
                  [classes.radioButtonLabelInactive]:
                    skillLevelsFilters !== d.id,
                }),
              }}
              value={d.id}
              label={d.name}
              control={
                <Radio
                  className={classes.radioButtonContent}
                  onClick={() =>
                    skillLevelsFilters === d.id
                      ? setSkillLevelsFilters(null)
                      : setSkillLevelsFilters(d.id)
                  }
                  variant={"contained"}
                  color={"default"}
                >
                  {d.name}
                </Radio>
              }
            />
          ))}
        </RadioGroup>
      </Box>
      <Box className={classes.filterContainer}>
        <Box className={classes.filterInterContainer}>
          <Box className={classes.filterInterIconContainer}>
            <MdOutlineFilterAlt size="1.5rem" />
          </Box>
          <Box className={classes.filterInterContentContainer}>
            {coachs.map((coach, i) => {
              const isInFilters = coachsFilters?.includes(coach.id);
              return (
                <Box
                  key={`${coach.id}-${i}`}
                  className={
                    isInFilters
                      ? classes.filterInterContentContainerItemActive
                      : classes.filterInterContentContainerItemInactive
                  }
                  onClick={(e) => {
                    return isInFilters
                      ? setCoachsFilters(
                          coachsFilters.filter((c) => c !== coach.id)
                        )
                      : setCoachsFilters([...coachsFilters, coach.id]);
                  }}
                >
                  <Tooltip title={`${coach.lastName} ${coach.firstName}`}>
                    <Avatar
                      variant="square"
                      className={classes.filterInterContentContainerItemAvatar}
                      style={{
                        backgroundColor: isInFilters ? coach.color : "gray",
                        border: `2px solid ${
                          isInFilters ? coach.color : "gray"
                        }`,
                        color: isInFilters ? "white" : "gray",
                      }}
                    >{`${coach.lastName.charAt(0)}${coach.firstName.charAt(
                      0
                    )}`}</Avatar>
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
/* */
const renderList = ({ classes, lessons = [], loading, history, account, golf, registrations }) => {
  if (loading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress size={32} thickness={4} color="secondary" />
      </div>
    );
  }

  if (lessons.filter((d) => moment(d.endsAt).isAfter()).length === 0) {
    return (
      <div className={classes.loaderContainer}>
        <Typography variant="h5">{"Aucun cours."}</Typography>
      </div>
    );
  }

  return (
    <Grid container spacing={24}>
      {lessons.filter((d) => moment(d.endsAt).isAfter()).map((item) => renderLesson({ classes, item, history, golf, account, entryList: registrations[item.id] }))}
    </Grid>
  );
};

/* */
const renderLesson = ({ classes, item, history, golf, account, entryList = [] }) => {
  const {
    id,
    name,
    price,
    startsAt,
    description,
    attendeeCount,
    maxGroupSize,
    slotCount,
    headCoach,
    skillLevels,
    lifecycle = "registration",
  } = item;
  let icon;
  let status;
  let statusText;
  let classCSS = classes.lifecycle;
  if (lifecycle === "registration") {
    icon = <LockIcon />;
    ({ status, statusText } = Academy.functions.getRegistrationStatus(
      { ...item, openOnline: true },
      golf?.options
    ));
    classCSS = classes[getColorStatus(status)];
    icon =
      status > 0 ? (
        <LockOpenIcon className={classCSS} />
      ) : (
        <LockIcon className={classCSS} />
      );
    
    if (moment(startsAt).isBefore()) {
      icon = <GolfCourseIcon className={classCSS} />;
      statusText = "En cours";
      classCSS = classes['inProgress'];
    }
  } else if (lifecycle === "teetime") {
    icon = <AccessTimeIcon className={classCSS} />;
    statusText = "Départs publiés";
  } else if (lifecycle === "results") {
    icon = <FormatListNumberedIcon className={classCSS} />;
    statusText = "Résultats publiés";
  }

  if (moment(startsAt).isAfter() && attendeeCount + entryList.filter((e) => e.status === 'WAITLISTED').length >= maxGroupSize) {
    classCSS = classes['full'];
    icon = <LockIcon className={classCSS} />;
    statusText = "Complet";
  }

  if (entryList.map((e) => (e.customer))?.map((c) => c.id).includes(account?.id)) {
    icon = <CheckIcon className={classCSS} />;
    statusText = "Vous êtes inscrit à ce cours";
  }

  return (
    <Grid item key={id} md={6} sm={10} xs={12}>
      <ButtonBase
        disableRipple
        component="div"
        onClick={() => goToView(item, history)}
        className={classes.itemContainer}
      >
        <Paper elevation={2} className={classes.item}>
          <Box className={classes.headerContainer}>
            <Box className={classes.proContent}>
              <Tooltip title="Pro" placement="right">
                <Chip
                  icon={<MdOutlineSchool color="white" />}
                  className={classes.pro}
                  style={{ backgroundColor: headCoach?.color, color: "white" }}
                  color="default"
                  size="small"
                  label={`${headCoach?.lastName} ${headCoach?.firstName}`}
                />
              </Tooltip>
            </Box>
            <Box className={classes.hints}>
              <Tooltip title="Nombres d'inscrits" placement="bottom">
                <Chip
                  icon={<MdOutlineAccountCircle />}
                  className={classes.players}
                  style={{
                    backgroundColor: getminMaxColor(
                      attendeeCount + entryList.filter((e) => e.status === 'WAITLISTED').length,
                      maxGroupSize
                    ),
                  }}
                  color="default"
                  size="small"
                  label={`${attendeeCount + entryList.filter((e) => e.status === 'WAITLISTED').length} / ${maxGroupSize}`}
                />
              </Tooltip>
              <Tooltip title="Nombre de créneaux" placement="bottom">
                <Chip
                  icon={<MdOutlineBook />}
                  className={classes.slots}
                  color="default"
                  size="small"
                  label={slotCount}
                />
              </Tooltip>
              <Tooltip title="Prix" placement="bottom">
                <Chip
                  icon={<MdOutlineEuro />}
                  className={classes.price}
                  color="default"
                  size="small"
                  label={parseFloat(price / 100).toFixed(2)}
                />
              </Tooltip>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <Box alignSelf={'center'}>
              <CalendarDay datetime={moment(startsAt)} />
            </Box>
            <Box
              ml={2}
              flex={1}
              display="flex"
              flexDirection="column"
              className={classes.oHidden}
            >
              <Typography
                color="secondary"
                variant="subtitle1"
                className={classes.trnName}
              >
                {name}
              </Typography>
              {icon && statusText && (
                <Box display="flex" flexDirection="row" alignItems="center">
                  {icon}&nbsp;
                  <Typography className={classCSS}>{statusText}</Typography>
                </Box>
              )}
              {skillLevels.length > 0 && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mt={1}
                >
                  <MdOutlineDeveloperBoard size={24} />
                  &nbsp;
                  <Typography>
                    {skillLevels.map((skill) => skill.name).join(", ")}
                  </Typography>
                </Box>
              )}
              {description && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mt={1}
                >
                  <InfoOutlinedIcon />
                  &nbsp;
                  <Box style={{ maxHeight: 100, overflowY: "auto" }}>
                    <Typography>{description.replace("<br/>", " ")}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      </ButtonBase>
    </Grid>
  );
};

const GroupLesson = (props) => {
  let history = useHistory();
  const queryClient = useQueryClient();
  const { golf } = props;
  const [lessons, setLessons] = useState([]);
  const [calendar, setCalendar] = useState(moment());
  const [coachs, setCoachs] = useState([]);
  const [coachsFilters, setCoachsFilters] = useState(null);
  const [skillLevels, setSkillLevels] = useState([]);
  const [skillLevelsFilters, setSkillLevelsFilters] = useState(null);
  const [registrations, setRegistrations] = useState({});

  useEffect(() => {
    queryClient.removeQueries(["academy-group-lessons"]);
  }, [calendar, queryClient]);

  const { isLoading: coachsLoading } = useQuery(
    ["academy-coachs"],
    async () => {
      const coachsData = await Academy.api.getCoachs(masterClient);
      if (!coachsFilters) {
        setCoachsFilters(coachsData.map(({ id }) => id));
      }
      setCoachs(coachsData);
      return coachsData;
    },
    {
      enabled: !!golf?.id,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const { isLoading: skillLevelsLoading } = useQuery(
    ["academy-skill-levels"],
    async () => {
      const skillLevelsData = await Academy.api.getSkillLevels(masterClient);
      setSkillLevels(skillLevelsData);
      return skillLevelsData;
    },
    {
      enabled: !!coachsFilters,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const { isLoading: lessonsLoading } = useQuery(
    ["academy-group-lessons", calendar.format("MMMM YYYY"), golf?.id],
    async () => {
      const groupLessonsData = await Academy.api.getGroupLessons(masterClient, {
        filter: {
          startsAt: {
            gte: moment(calendar).startOf("month").format(),
            lte: moment(calendar).endOf("month").format(),
          },
        },
        sort: [{ startsAt: "ASC" }],
      });
      setLessons(groupLessonsData.items);
      return groupLessonsData;
    },
    {
      enabled: !!coachsFilters,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  useQueries({
    queries: [...lessons.map((lesson) => ({ queryKey: ["academy-lesson-registrations", lesson.id], queryFn: async () => {
      const data = await Academy.api.getLessonRegistrations(masterClient, { lessonId: lesson.id })
      setRegistrations(old => ({
        ...old,
        [lesson.id]: data,
      }))
      return data
    } }))],
  });

  return (
    <Box>
      <RenderHeader calendar={calendar} setCalendar={setCalendar} />
      {useFilters({
        ...props,
        coachs,
        coachsFilters,
        setCoachsFilters,
        skillLevels,
        skillLevelsFilters,
        setSkillLevelsFilters,
      })}
      {renderList({
        ...props,
        loading: lessonsLoading || coachsLoading || skillLevelsLoading,
        lessons: lessons.filter(
          (item) =>
            coachsFilters.includes(item?.headCoach?.id) &&
            (skillLevelsFilters
              ? item.skillLevels
                  .map(({ id }) => id)
                  .includes(skillLevelsFilters)
              : true)
        ),
        history,
        golf,
        registrations,
      })}
    </Box>
  );
};
/* */
const mapStateToProps = ({ app: { golf, account, user } }) => ({
  golf,
  user,
  account,
});

/* */
const StyledComponent = withStyles(styles)(GroupLesson);

export default connect(mapStateToProps)(withWidth()(StyledComponent));

