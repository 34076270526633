import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { ArrowForwardIos as ForwardIcon } from '@material-ui/icons';
import { HELP_URL, Booking, Activity } from '@aps-management/primapp-common';
import { Screen } from '_components/core';
import { useContextSelector } from '@fluentui/react-context-selector';
import moment from 'moment';
import SetActivity from './components/setters/SetActivity';
import OptionsPanel from './components/controls/OptionsPanel';
import { styles } from './styles/Activity.styles';
import ActivityContext, { ActivityProvider } from './contexts/ActivityContext';
import RenderDisponibilityContainer from './components/controls/ActivityDisponibilityContainer';

const SearchContent = (props) => {
  const {
    account, search: { date, players }, activityData, history, classes, width, golf,
  } = props;
  const selectedDate = moment(date)?.isValid() ? moment(date) : moment();
  const {
    error,
    load,
    loading,
    activityState,
    activeOption,
    setActiveOption,
    selectedDuration,
    setSelectedDuration,
    isPublic,
    course,
  } = useContextSelector(ActivityContext, (c) => c);

  return (
    <Screen
      noDrawer={isPublic}
      layout={'fluid'}
      ForwardIcon={ForwardIcon}
      helpURL={`${HELP_URL}/r%C3%A9server`}
      onRetry={load}
      title="Activités"
      error={error}
    >
      <SetActivity />
      {!loading
        // Affichage conditionnel en fonction de showDisponibilities
        && (activityData?.showDisponibilities ? (
          <RenderDisponibilityContainer />
        ) : (
          <OptionsPanel
            players={players}
            date={selectedDate}
            width={width}
            classes={classes}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            selectedDuration={selectedDuration}
            course={course}
            activityState={activityState}
            setSelectedDuration={setSelectedDuration}
            history={history}
            golf={golf}
            account={account}
          />
        ))}
    </Screen>
  );
};
const Search = (props) => (
  <ActivityProvider>
    <SearchContent {...props} />
  </ActivityProvider>
);

const mapStateToProps = ({
  app, bookingData, bookingSearch, activityData,
}) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
  search: bookingSearch,
  activityData,
});

/* */
const StyledComponent = withStyles(styles)(Search);

const combinedActions = { ...Booking.actions, ...Activity.actions };

export default connect(
  mapStateToProps,
  combinedActions,
)(withWidth()(StyledComponent));
