import React from 'react';
import { TEE_TIME_MAX_PLAYERS } from '@aps-management/primapp-common';
import i18n from '_utils/i18n';
import SearchOption from './SearchOption';

const PlayersOption = ({
  classes, active, onClick, players, course,
}) => {
  const numberOfPlayers = players.length;
  const defaultCourse = { anonymous: true, maxPlayers: TEE_TIME_MAX_PLAYERS };
  const { anonymous = true, maxPlayers: maxNumberOfPlayers } = course || defaultCourse;
  const value = i18n.t('terms.players', { count: numberOfPlayers });
  let warningMessage = null;
  if (numberOfPlayers > maxNumberOfPlayers) {
    warningMessage = `Veuillez ne pas dépasser la limite autorisée (${maxNumberOfPlayers} maximum).`;
  }
  if (!anonymous && players.some((p) => p.type === 'anonymous')) {
    warningMessage = 'Veuillez identifier les joueurs.';
  }
  return (
    <SearchOption
      label="Joueur(s)"
      value={value}
      classes={classes}
      warningMessage={warningMessage}
      onClick={onClick}
      isExpanded={active}
    />
  );
};

export default PlayersOption;
