import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';

const DurationButton = ({
  duration,
  defaultSelected,
  selectedDuration,
  setSelectedDuration,
  setActiveOption,
  onDurationSelect,
  classes,
}) => {
  useEffect(() => {
    if (defaultSelected && !selectedDuration) {
      setSelectedDuration(duration);
    }
  }, [defaultSelected, selectedDuration, duration, setSelectedDuration]);

  const isSelected = selectedDuration
    && selectedDuration.hourPriceId === duration.hourPriceId;

  return (
    <Button
      className={`${classes.button} ${isSelected ? classes.selectedButton : ''}`}
      variant={isSelected ? 'contained' : 'outlined'}
      color={isSelected ? 'primary' : 'secondary'}
      onClick={() => {
        setSelectedDuration(duration);
        setActiveOption(0);
        if (onDurationSelect) {
          onDurationSelect(duration.pgbCodeProd);
        }
      }}
    >
      {duration.time} min
    </Button>
  );
};

export default DurationButton;
