import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {
  helpers,
  App,
  Booking,
} from '@aps-management/primapp-common';
import {
  Face as FaceIcon,
  FiberManualRecord as PersonIcon,
} from '@material-ui/icons';
import {
  colors,
  Grid,
  Paper,
  Avatar,
  Button,
  Typography,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import { Alert } from '_components/elements';
import apolloClient from '_utils/apolloClient';

/* */
const styles = theme => ({
  timeslot: {
    padding: theme.spacing.unit * 1.5,
  },
  time: {
    // flex: 1,
    minWidth: 56,
    fontWeight: 'bold',
  },
  players: {
    fontSize: 10,
    color: theme.palette.grey[300],
  },
  player: {
    color: colors.green[500],
  },
  face: {
    marginRight: theme.spacing.unit * 1,
  },
  name: {
    flex: 1,
    display: 'flex',
    overflowWrap: 'anywhere',
  },
  hcp: {
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: theme.spacing.unit * 1,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  alert: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
});

/* */
class Result extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { search, golf, account } = this.props;

    this.redirect = false;
    if (search.golf === null
      || search.date === null
      || search.course === null) {
      this.redirect = true;
      return;
    }

    this.match = App.functions.match(account, golf) || { isMember: false, reference: 'UNKNOWN' };

    this.state = {
      error: null,
      loading: true,
      // Data
      schedule: {},
      dailyNote: null,
      locations: null,
    };
    this.timeslotsList = [];
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    if (this.redirect) return;

    const { match } = this;
    const { search } = this.props;

    this.setState({
      error: null,
      loading: true,
      // Data
      schedule: {},
      locations: null,
    });

    const params = {
      fullday: true,
      date: search.date,
      golfId: search.golf.id,
      players: [match.reference],
      courseId: search.course.id,
      courseType: search.course.type,
    };

    // Get schedules, course + accessories
    Booking.api.getSchedules(apolloClient, params)
      .then(([{ courseSchedule }]) => {
        const schedules = {};
        const locations = [];

        const { dailyNote } = courseSchedule;

        schedules.course = Booking.functions.processSchedule('course', courseSchedule.teetimes);
        const detailedSchedule = Booking.functions.getDetailedSchedule(
          schedules, { accessories: [], players: [] },
        );
        this.timeslotsList = Object.keys(detailedSchedule).sort((a, b) => a - b);

        if (detailedSchedule) {
          Object.values(detailedSchedule).forEach(({ others }) => {
            if (others) {
              others.forEach((other) => {
                if (other && other.location) {
                  if (!locations.find(l => l.id === other.location.id)) {
                    locations.push(other.location);
                  }
                }
              });
            }
          });
        }
        this.setState({
          dailyNote,
          locations,
          loading: false,
          schedule: detailedSchedule,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message,
        });
      });
  };

  /* */
  displayOtherName(other, key) {
    const { classes, width } = this.props;

    const isAnonymous = `${other.firstname || ''}${other.lastname || ''}`.trim() === '';
    const br = isWidthUp('md', width) ? (<br />) : '\xa0'; // &nbsp;
    const fullname = isAnonymous
      ? (<span>Joueur{br}Anonyme</span>)
      : (<span>{other.firstname}{other.firstname && br}{other.lastname}</span>);
    const location = other.location && (
      <span title={other.location.name}>{` (${other.location.name[0]})`.toUpperCase()}</span>
    );

    return (
      <Grid item key={key} sm={6} xs={12}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row">
          {!other.index && <Avatar className={classes.hcp}><FaceIcon /></Avatar>}
          {!!other.index && <Avatar className={classes.hcp}>{other.index.toFixed(1)}</Avatar>}
          <Typography className={classes.name} variant="body2">{this.match.isMember ? fullname : '-'}{location}</Typography>
        </Box>
      </Grid>
    );
  }

  /* */
  renderDailyNote() {
    const { classes } = this.props;
    const { dailyNote } = this.state;

    if (!dailyNote) return null;

    return (
      <Alert variant="info" className={classes.alert}>
        {dailyNote.split('\\n').map((line, i) => (
          <Fragment key={i}>{line}<br /></Fragment>
        ))}
      </Alert>
    );
  }

  /* */
  renderEmpty() {
    return (
      <Box textAlign="center">
        <Typography
          paragraph
          variant="h6"
          align="center"
          color="textSecondary">
          {'Aucun créneau.'}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.goBack()}>
          {'Retour'}
        </Button>
      </Box>
    );
  }

  /* */
  renderTimeslot = (time, key) => {
    const { classes } = this.props;
    const { schedule } = this.state;

    const {
      others,
      availability,
    } = schedule[time];

    const hasOthers = Boolean(others && others.length);

    return (
      <Grid item key={key} md={6} sm={10} xs={12}>
        <Paper className={classes.timeslot}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            mb={(hasOthers && 1) || 0}>
            <Typography
              className={classes.time}
              component="span"
              variant="h6">
              {Booking.functions.formatTime(time)}
            </Typography>
            <div
              className={classes.players}
              title={i18n.t('terms.availability', { count: availability })}>
              {[...Array(availability).keys()].map(i => (
                <PersonIcon key={i} className={classes.player} fontSize="inherit" />
              ))}
              {others && others.map((o, i) => (<PersonIcon key={i} fontSize="inherit" />))}
            </div>
          </Box>
          {hasOthers && (
            <Grid container spacing={24}>
              {others && others.map((o, i) => this.displayOtherName(o, i))}
            </Grid>
          )}
        </Paper>
      </Grid>
    );
  }

  /* */
  renderSchedule() {
    if (this.timeslotsList.length > 0) {
      return (
        <Grid
          container
          spacing={24}
          justify="center">
          {this.timeslotsList.map(this.renderTimeslot)}
        </Grid>
      );
    }

    return this.renderEmpty();
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to="/schedule" />;
    }

    const { search } = this.props;
    const {
      error,
      loading,
      locations,
    } = this.state;

    const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(search.date)));
    const courseName = `${i18n.t(`terms.course_type_${search.course.type}`)} "${search.course.name}"`;

    return (
      <Screen
        error={error}
        title="Planning"
        loading={loading}
        onRetry={this.load}
        onBackPress={() => this.props.history.goBack()}>
        <Typography
          align="center"
          component="h2"
          variant="h5">
          {dateTxt}
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          variant="h6">
          {courseName}
        </Typography>
        {this.renderDailyNote()}
        <Box
          mt={3}
          mb={1}
          display="flex"
          flexDirection="row">
          {locations && locations.map(l => (
            <Typography variant="caption" display="block" gutterBottom key={l.id}>
              {`(${l.name[0]}) ${l.name}`}
            </Typography>
          ))}
        </Box>
        {!loading && this.renderSchedule()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { golf, account }, bookingData, bookingSearch }) => ({
  golf,
  account,
  data: bookingData,
  search: bookingSearch,
});

const StyledComponent = withStyles(styles)(Result);

export default connect(
  mapStateToProps,
  Booking.actions,
)(withWidth()(StyledComponent));
