import React from 'react';

/**
 * Composant SkeletonLoader pour afficher un loader de type skeleton.
 * @returns {JSX.Element} Le composant SkeletonLoader.
 */
const SkeletonLoader = () => (
  <svg width="100%" height="50" xmlns="http://www.w3.org/2000/svg">
    <rect
      id="pulseBar"
      fill="rgb(170,170,170)"
      width="100%"
      height="100%"
      rx="5"
      ry="5"
    />
  </svg>
);

export default SkeletonLoader;
