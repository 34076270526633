import React from 'react';
import { Menu } from '@material-ui/core';
import { BasePicker, Calendar } from 'material-ui-pickers';

/**
 * Composant DatePickerMenu pour la sélection d'une date.
 * @param {Object} props - Les propriétés du composant.
 * @param {HTMLElement} props.datePickerAnchor - L'élément d'ancrage du menu.
 * @param {Function} props.handleClose - Fonction de fermeture du menu.
 * @param {Function} props.setDate - Fonction pour mettre à jour la date.
 * @param {Object} props.selectDateTime - Objet Moment représentant la date sélectionnée.
 * @returns {JSX.Element} Le composant DatePickerMenu.
 */
const DatePickerMenu = ({
  datePickerAnchor, handleClose, setDate, selectDateTime,
}) => (
  <Menu
    id="lock-menu"
    anchorEl={datePickerAnchor}
    open={Boolean(datePickerAnchor)}
    onClose={handleClose}
  >
    <BasePicker onChange={() => handleClose()} value={selectDateTime.toDate()}>
      {({ date: currentDate }) => (
        <Calendar
          date={currentDate}
          disablePast={true}
          onChange={(e) => {
            setDate(e);
            handleClose();
          }}
        />
      )}
    </BasePicker>
  </Menu>
);

export default DatePickerMenu;
