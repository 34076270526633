import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  IconButton,
  Divider,
} from '@material-ui/core';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { useContextSelector } from '@fluentui/react-context-selector';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { Booking } from '@aps-management/primapp-common';
import DatePickerMenu from './DatePickerMenu';
import RenderRow from './RenderRow';
import ActivityContext from '../../contexts/ActivityContext';
import SkeletonLoader from './SkeletonLoader';
import PublicBookingPlayerSelector from '../../../Public/PublicBooking/PublicBookingPlayerSelector';
/**
 * Composant principal pour afficher les disponibilités d'activités.
 * @param {Object} props - Les propriétés du composant.
 * @param {Function} props.setDate - Fonction pour mettre à jour la date.
 * @param {Object} props.search - Données de recherche issues de Redux.
 * @param {Object} props.classes - Classes de style injectées par withStyles.
 * @returns {JSX.Element} Le composant RenderDisponibility.
 */
const RenderDisponibility = (props) => {
  const {
    setDate, search: { date }, classes,
  } = props;
  const {
    loading,
    selectedActivity,
    types,
    rawSlots,
    isPublic,
    rawSlotsIsFetching,
    selectedDuration,
    setSelectedDuration,
    typesFilters,
    setTypesFilters,
    updateSelectedSlot,
  } = useContextSelector(ActivityContext, (c) => c);

  const availabilitiesError = null;

  const [durations, setDurations] = useState([]);
  const [selectDateTime, setSelectDateTime] = useState(
    date && moment(date).isValid() ? moment(date) : moment(),
  );
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);

  /**
   * Extrait et trie les durées uniques depuis les hourPrice de l'activité.
   * @param {Array} hourPrice - Tableau d'objets contenant une propriété "time".
   * @returns {Array<number>} Tableau trié de durées en minutes.
   */
  const getAllDurationsFromHourPrices = useCallback((hourPrice) => {
    const setOfDurations = new Set();
    hourPrice.forEach((obj) => {
      setOfDurations.add(obj.time);
    });
    return Array.from(setOfDurations).sort((a, b) => a - b);
  }, []);

  // Met à jour la liste des durées à chaque changement d'activité
  useEffect(() => {
    if (selectedActivity?.hourPrice) {
      const durs = getAllDurationsFromHourPrices(selectedActivity.hourPrice);
      setDurations(durs);
    }
  }, [selectedActivity, getAllDurationsFromHourPrices]);

  /**
   * Ouvre le menu de sélection de date.
   * @param {React.SyntheticEvent} e - L'événement déclenché.
   */
  const handleOpenDatePicker = (e) => {
    setDatePickerAnchor(e.currentTarget);
  };

  /**
   * Ferme le menu de sélection de date.
   */
  const handleCloseDatePicker = () => {
    setDatePickerAnchor(null);
  };

  /**
   * Gère la réservation d'un créneau.
   * @param {Object} payload - Les données de réservation.
   */
  const handleBook = () => {
    // console.log('Booking =>', payload);
  };

  const handleSlotSelection = (slotInfo) => {
    // Mettre à jour le créneau sélectionné dans le contexte
    updateSelectedSlot(slotInfo);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box>
        {/* Barre de navigation pour la date */}
        <Box className={classes.dateContainer}>
          <Box style={{
            flex: 0.5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
          }}>
            <IconButton
              onClick={() => {
                const newDate = selectDateTime.clone().subtract(1, 'day');
                setSelectDateTime(newDate);
                setDate(newDate);
              }}
              color="primary"
            >
              <ChevronLeftIcon />
            </IconButton>
            <Button
              aria-owns={datePickerAnchor ? 'simple-menu' : undefined}
              aria-haspopup="true"
              style={{ textTransform: 'none', fontWeight: 500, fontSize: 18 }}
              onClick={handleOpenDatePicker}
            >
              {selectDateTime.format('dddd DD MMMM YYYY').toUpperCase()}
            </Button>
            <IconButton
              onClick={() => {
                const newDate = selectDateTime.clone().add(1, 'day');
                setSelectDateTime(newDate);
                setDate(newDate);
              }}
              color="primary"
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
          {isPublic && (
            <Box style={{ flex: 0.5 }}>
              <PublicBookingPlayerSelector maxPlayers={selectedActivity?.maxPlayers} />
            </Box>
          )}
        </Box>
        <DatePickerMenu
          datePickerAnchor={datePickerAnchor}
          handleClose={handleCloseDatePicker}
          setDate={(newDate) => {
            setSelectDateTime(newDate);
            setDate(newDate);
          }}
          selectDateTime={selectDateTime}
        />
        {selectedDuration && (
          <Box className={classes.timeContainer}>
            <RadioGroup
              aria-label="Durées"
              name="durationRadio"
              value={String(selectedDuration.time)}
              className={classes.radioGroup}
            >
              {durations.map((d, i) => (
                <FormControlLabel
                  key={`duration-${d}-${i}`}
                  value={String(d)}
                  label={`${d} min`}
                  className={classNames(classes.radioButton, {
                    [classes.radioButtonActive]: selectedDuration.time === d,
                    [classes.radioButtonInactive]: selectedDuration.time !== d,
                  })}
                  classes={{
                    root: classes.radioButtonRoot,
                    label: classNames(classes.radioButton, {
                      [classes.radioButtonLabelActive]:
                        selectedDuration.time === d,
                      [classes.radioButtonLabelInactive]:
                        selectedDuration.time !== d,
                    }),
                  }}
                  control={
                    <Radio
                      className={classes.radioButtonContent}
                      onClick={() => {
                        // Recherche l'objet complet dans hourPrice qui correspond au temps choisi
                        const newSelected = selectedActivity.hourPrice.find(
                          (hp) => hp.time === d,
                        );
                        setSelectedDuration(newSelected);
                      }}
                      variant="contained"
                      color="default"
                    />
                  }
                />
              ))}
            </RadioGroup>
          </Box>
        )}
        {/* Filtres par type */}
        {selectedActivity.okType && types && types.length > 0 && (
          <Box className={classes.filterContainer}>
            <Box className={classes.filterInterContainer}>
              <Box className={classes.filterInterIconContainer}>
                <MdOutlineFilterAlt size="1.5rem" />
              </Box>
              <Box className={classes.filterInterContentContainer}>
                {types.map((type, i) => {
                  const isInFilters = typesFilters.includes(type.id);
                  return (
                    <Button
                      key={`${type.id}-${i}`}
                      variant={isInFilters ? 'contained' : 'outlined'}
                      onClick={() => {
                        if (isInFilters) {
                          setTypesFilters(
                            typesFilters.filter((c) => c !== type.id),
                          );
                        } else {
                          setTypesFilters([...typesFilters, type.id]);
                        }
                      }}
                      style={{
                        backgroundColor: isInFilters
                          ? type.bgColor
                          : 'transparent',
                        color: isInFilters ? type.textColor : type.bgColor,
                        borderColor: type.bgColor,
                        margin: '4px',
                        textTransform: 'none',
                        fontWeight: 800,
                        textShadow: '1px 1px 0 #00000033, -1px 1px 0 #00000033, -1px -1px 0 #00000033, 1px -1px 0 #00000033',
                      }}
                    >
                      {type.firstName}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}
        {/* Affichage des créneaux */}
        <Box className={classes.lessonsContainer}>
          {((loading || rawSlotsIsFetching)
            && Array(10)
              .fill(0)
              .map((_, i) => (
                <Box
                  key={`skeleton-${i}`}
                  display="flex"
                  flexDirection="column"
                >
                  <Divider />
                  <Box padding={1} display="flex" alignItems="center">
                    <SkeletonLoader />
                  </Box>
                </Box>
              )))
            || (availabilitiesError || rawSlots.length === 0 ? (
              <div className={classes.loaderContainer}>
                <Typography variant="h6">Aucun créneau disponible</Typography>
              </div>
            ) : (
              rawSlots.map((slot, index) => {
                const isPassed = moment(`${selectDateTime.format('YYYY-MM-DD')} ${moment(slot.hour, 'HHmm').format('HH:mm:00')}`, 'YYYY-MM-DD HH:mm:ss').isBefore();
                if (isPassed) return null;
                return (
                  <Box
                    key={`${slot.hour}-${index}`}
                    display="flex"
                    flexDirection="column"
                  >
                    <Divider />
                    <Box padding={1} display="flex" alignItems="center">
                      <Typography className={classes.loadingContainerTime}>
                        {moment(slot.hour, 'HHmm').format('HH:mm')}
                      </Typography>
                      <Box
                        className={'slots-container'}
                        display="flex"
                        flexWrap="wrap"
                        flexDirection="column"
                        justifyContent="center"
                        marginBottom={0}
                        width="100%"
                      >
                        {slot.activitySlots.map((detail, i) => {
                          if (!typesFilters.includes(detail.typeId)) return null;
                          const foundType = types.find(
                            (t) => t.id === detail.typeId,
                          );
                          const label = foundType
                            ? foundType.firstName
                            : null;
                          const bgColor = foundType ? foundType.bgColor : 'gray';
                          return (
                            <RenderRow
                              key={`${detail.typeId}-${i}`}
                              selectedActivity={selectedActivity}
                              detail={detail}
                              label={label}
                              bgColor={bgColor}
                              handleBook={handleBook}
                              selectDateTime={selectDateTime}
                              slot={slot}
                              durationTime={selectedDuration}
                              classes={classes}
                              moment={moment}
                              handleSlotSelection={handleSlotSelection}
                              updateSelectedSlot={updateSelectedSlot}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ))}
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = ({ app, bookingData, bookingSearch }) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
  search: bookingSearch,
});

export default connect(
  mapStateToProps,
  Booking.actions,
)(RenderDisponibility);
