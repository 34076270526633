import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { TbSportBillard, TbStretching } from 'react-icons/tb';
import {
  MdOutlineSportsGymnastics,
  MdOutlineSportsHandball,
  MdOutlineSportsTennis,
  MdPool,
  MdSportsSoccer,
  MdHome,
  MdSportsBasketball,
  MdOutlineSportsMartialArts,
  MdSchool,
  MdSportsGolf,
} from 'react-icons/md';
import {
  GiTargetArrows,
  GiCardRandom,
  GiBridge,
  GiPaddles,
  GiVolleyballBall,
  GiMeditation,
  GiWaterSplash,
  GiFlame,
} from 'react-icons/gi';
import { PiRacquetBold } from 'react-icons/pi';
import { FaBicycle, FaHorse } from 'react-icons/fa6';
import { IoFitness } from 'react-icons/io5';
import { FaSpa, FaBath, FaTableTennis } from 'react-icons/fa';
/* Fonction de mappage dynamique des icônes */
const getActivityIcon = (activityName, active, disabled) => {
  // Si désactivé, utiliser une couleur grise
  let iconColor;
  if (disabled) {
    iconColor = '#aaa';
  } else if (active) {
    iconColor = 'white';
  } else {
    iconColor = '#19363C';
  }
  const iconMap = {
    SIMULATEUR: <MdSportsGolf size={24} style={{ color: iconColor }} />,
    TRACKMAN: <GiTargetArrows size={24} style={{ color: iconColor }} />,
    INDOOR: <MdHome size={24} style={{ color: iconColor }} />,
    BILLARD: <TbSportBillard size={28} style={{ color: iconColor }} />,
    CARDGAME: <GiCardRandom size={24} style={{ color: iconColor }} />,
    BRIDGE: <GiBridge size={24} style={{ color: iconColor }} />,
    SEMINAR: <MdSchool size={24} style={{ color: iconColor }} />,
    TENNIS: <MdOutlineSportsTennis size={24} style={{ color: iconColor }} />,
    SQUASH: <PiRacquetBold size={24} style={{ color: iconColor }} />,
    PADEL: <MdOutlineSportsTennis size={24} style={{ color: iconColor }} />,
    PICKLEBALL: <GiPaddles size={24} style={{ color: iconColor }} />,
    'TABLE TENNIS': <FaTableTennis size={24} style={{ color: iconColor }} />,
    BADMINTON: <MdOutlineSportsTennis size={24} style={{ color: iconColor }} />,
    VOLLEY: <GiVolleyballBall size={24} style={{ color: iconColor }} />,
    BASKETBALL: <MdSportsBasketball size={24} style={{ color: iconColor }} />,
    HANDBALL: <MdOutlineSportsHandball size={24} style={{ color: iconColor }} />,
    FOOTBALL: <MdSportsSoccer size={24} style={{ color: iconColor }} />,
    'MARTIAL ART': <MdOutlineSportsMartialArts size={24} style={{ color: iconColor }} />,
    GYM: <MdOutlineSportsGymnastics size={24} style={{ color: iconColor }} />,
    FITNESS: <IoFitness size={24} style={{ color: iconColor }} />,
    CYCLING: <FaBicycle size={24} style={{ color: iconColor }} />,
    YOGA: <GiMeditation size={24} style={{ color: iconColor }} />,
    PILATES: <TbStretching size={24} style={{ color: iconColor }} />,
    SWIMMING: <MdPool size={24} style={{ color: iconColor }} />,
    AQUAGYM: <GiWaterSplash size={24} style={{ color: iconColor }} />,
    AQUABIKE: <FaBicycle size={24} style={{ color: iconColor }} />,
    SPA: <FaSpa size={24} style={{ color: iconColor }} />,
    SAUNA: <GiFlame size={24} style={{ color: iconColor }} />,
    HAMMAM: <FaBath size={24} style={{ color: iconColor }} />,
    'HORSE RIDING': <FaHorse size={24} style={{ color: iconColor }} />,
  };

  return iconMap[activityName.toUpperCase()]
    || <MdOutlineSportsHandball style={{ color: iconColor }} />;
};

const styles = (theme) => ({
  container: {
    padding: 16,
    marginRight: 16,
    minWidth: 100,
    cursor: 'pointer',
  },
  name: {
    marginTop: 8,
    userSelect: 'none',
    color: theme.palette.secondary.dark,
  },
  activeContainer: {
    backgroundColor: theme.palette.secondary.dark,
  },
  activeName: {
    marginTop: 8,
    userSelect: 'none',
    color: theme.palette.common.white,
  },
  disabledContainer: {
    backgroundColor: '#eee',
    cursor: 'default',
  },
  disabledName: {
    marginTop: 8,
    userSelect: 'none',
    color: '#aaa',
  },
});

const ActivityButton = withStyles(styles)(({
  name,
  active,
  activityName,
  onClick,
  disabled,
  classes,
}) => (
  <Paper
    elevation={2}
    onClick={disabled ? null : onClick}
    className={classNames(
      classes.container,
      {
        [classes.activeContainer]: active && !disabled,
        [classes.disabledContainer]: disabled,
      },
    )}
  >
    <Box display="flex" alignItems="center" flexDirection="column">
      {getActivityIcon(activityName, active && !disabled, disabled)}
      <Typography
        component="span"
        className={classNames({
          [classes.name]: !active || disabled,
          [classes.activeName]: active && !disabled,
          [classes.disabledName]: disabled,
        })}
      >
        {name}
      </Typography>
    </Box>
  </Paper>
));

const ActivityNewSelector = ({
  data,
  value = 0,
  onChange,
  noDefault,
}) => {
  if (data.length === 0) return null;

  return (
    <Box mb={3} display="flex" flexDirection="row">
      {!noDefault && (
        <ActivityButton
          name="Cours Collectif"
          active={value === 0}
          onClick={() => onChange()}
          activityName="RUNNING"
          disabled={false}
        />
      )}
      {data.map((res) => !res?.abandon && (
        <ActivityButton
          key={res.id}
          name={res.name}
          activityName={res.name}
          active={value === Number(res.id)}
          onClick={() => onChange(Number(res.id))}
        />
      ))}
    </Box>
  );
};

export default ActivityNewSelector;
