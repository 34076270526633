import React from 'react';
import { connect } from 'react-redux';
import { Steps } from 'intro.js-react';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { App, Booking, TEE_TIME_MAX_PLAYERS } from '@aps-management/primapp-common';
import {
  Chip,
  Paper,
  Avatar,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  Face as FaceIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import apolloClient from '_utils/apolloClient';
import ChoosePartners from '_components/ChoosePartners';
import { Alert, Incrementer } from '_components/elements';

/* */
const styles = (theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
  },
  chip: { margin: theme.spacing.unit * 1 },
  helpButton: { float: 'right' },
});

/* */
const steps = [
  {
    element: '#bp-adjust',
    intro: 'Définissez le nombre de joueur.',
  },
  {
    element: '#bp-player',
    intro: 'Identifiez les joueurs anonymes en cliquant dessus.',
  },
];

/* */
const USER_OPTION_NAME = 'setPlayersHintMessageRead';

/* */
class SetPlayers extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { app: { account, golf } } = props;

    this.state = {
      openDialog: false,
      stepsEnabled: false,
    };

    this.accountMatch = App.functions.match(account, golf);
    this.allowBookForOthers = golf.options.booking.forOthers || false;
  }

  /* */
  componentDidMount() {
    const {
      app: { user },
      search: { course, players },
    } = this.props;

    const { maxPlayers: maxNumberOfPlayers } = course || { maxPlayers: TEE_TIME_MAX_PLAYERS };

    // autopay hint steps
    const autoplay = !(user.options && user.options[USER_OPTION_NAME]) && (maxNumberOfPlayers > 1);
    if (autoplay && (players.length === 1)) this.handleIncrease();

    this.setState({ stepsEnabled: autoplay });
  }

  /* */
  handleCompleteSteps = () =>
    App.api.setUserOption(apolloClient, { name: USER_OPTION_NAME, value: Date.now() });

  /* */
  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  /* */
  handleClose = () => {
    this.setState({ openDialog: false });
  };

  /* */
  handleIncrease = () => {
    this.props.addPlayer({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    });
  }

  /* */
  handleDecrease = () => {
    const { search: { players } } = this.props;
    this.props.adjustPlayers(players.length - 1);
  }

  /* */
  handleReplacePartner = (player, index) => {
    if (typeof index === 'number') { // unselect
      return this.props.replacePlayer(player, index);
    }

    // select
    const { accountMatch } = this;
    const isOwner = player.type === 'member'
      && player.reference === accountMatch.reference;

    return this.props.replacePlayer({
      ...player,
      ...(isOwner && { type: 'owner' }),
    });
  }

  /* */
  renderMore() {
    const { openDialog } = this.state;
    const { allowBookForOthers } = this;
    const { classes, search: { course, players } } = this.props;
    const { maxPlayers: maxNumberOfPlayers } = course || { maxPlayers: TEE_TIME_MAX_PLAYERS };

    return (
      <Box mt={2} position="relative">
        {(maxNumberOfPlayers > 1) && (<IconButton
          aria-label="Info"
          className={classes.helpButton}
          onClick={() => this.setState({ stepsEnabled: true })}>
          <HelpOutlineIcon />
        </IconButton>)}
        <Typography
          gutterBottom
          variant="h6"
          component="p">
          {'Les joueurs'}
        </Typography>
        {players.map(this.renderChip)}
        <ChoosePartners
          partners={players}
          open={openDialog}
          onClose={this.handleClose}
          disableOwner={!allowBookForOthers}
          replacePartner={this.handleReplacePartner} />
      </Box>
    );
  }

  /* */
  renderChip = (player, i, arr) => {
    const { classes } = this.props;
    const { allowBookForOthers } = this;

    const isOwner = player.type === 'owner';
    const isAnonymous = player.type === 'anonymous';

    const isDeletable = allowBookForOthers
      ? player.type !== 'anonymous'
      : (player.type !== 'owner' && player.type !== 'anonymous');

    const fullname = isAnonymous
      ? 'Joueur anonyme'
      : `${player.firstname} ${player.lastname}`;

    const customProps = {
      color: 'secondary',
      variant: 'default',
      avatar: <Avatar><FaceIcon /></Avatar>,
    };

    if (arr.length > 1 && i === 1) {
      // Change anchor if more than one player
      customProps.id = 'bp-player';
    }
    if (isAnonymous) {
      customProps.clickable = true;
      customProps.variant = 'outlined';
      customProps.onClick = this.handleClickOpen;
      customProps.avatar = null;
    }
    if (isOwner) {
      // Set anchor if only one player
      if (arr.length === 1) customProps.id = 'bp-player';
      customProps.color = 'primary';
    }
    if (isDeletable) {
      customProps.onDelete = (() => this.handleDeletePlayer(i));
    }

    return (
      <Chip
        key={i}
        label={fullname}
        className={classes.chip}
        {...customProps}
      />
    );
  }

  /* */
  handleDeletePlayer(i) {
    this.props.replacePlayer({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    }, i);
  }

  /* */
  renderIntro() {
    const { stepsEnabled } = this.state;
    // Todo component NStepGuide with IntroJS

    return (
      <Steps
        steps={steps}
        onExit={() => this.setState({ stepsEnabled: false })}
        onComplete={this.handleCompleteSteps}
        enabled={stepsEnabled}
        options= {{
          hidePrev: true,
          hideNext: true,
          doneLabel: 'OK',
          skipLabel: 'Passer',
          prevLabel: '<',
          nextLabel: '>',
        }}
        initialStep={0} />
    );
  }

  /* */
  render() {
    const { classes, search } = this.props;
    const { maxPlayers: maxNumberOfPlayers } = search.course
      || { maxPlayers: TEE_TIME_MAX_PLAYERS };

    const number = search.players.length;

    let alertViarant;
    let alertMessage;
    if (number > maxNumberOfPlayers) {
      alertViarant = 'error';
      alertMessage = 'Vous avez dépassé le nombre de joueurs maximum autorisé.';
    } else if (number === maxNumberOfPlayers) {
      alertViarant = 'info';
      alertMessage = 'Vous avez atteint le nombre de joueurs maximum autorisé.';
    }

    return (
      <Box m={1} mb={3}>
        {this.renderIntro()}
        <Typography
          paragraph
          component="p"
          variant="h6">
          {'Combien serez-vous ?'}
        </Typography>
        {alertMessage && <Alert variant={alertViarant}>{alertMessage}</Alert>}
        <Paper className={classes.paper}>
          <Incrementer
            id="bp-adjust"
            minValue={1}
            value={number}
            maxValue={maxNumberOfPlayers}
            onDecrease={this.handleDecrease}
            onIncrease={this.handleIncrease} />
          {this.renderMore()}
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = ({ app, bookingData, bookingSearch }) =>
  ({ app, data: bookingData, search: bookingSearch });

const StyledComponent = withStyles(styles)(SetPlayers);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
