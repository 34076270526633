import React, { useEffect } from 'react';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';
import Firebase from '_utils/firebase';

/* */
export default () => {
  const [showProblemResolver, setShowProblemResolver] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowProblemResolver(true);
    }, 20000);

    return () => {
      setShowProblemResolver(false);
      clearTimeout(timeout);
    };
  }, []);

  const clearCache = () => {
    localStorage.clear();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    window.location.reload();
    Firebase.logout();
  };

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <CircularProgress
        size={40}
        thickness={4}
        color="inherit" />
      {showProblemResolver && (
        <Box
          style={{
            marginTop: 20,
            position: 'absolute',
            top: '55%',
          }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>
            {'Chargement trop long ?'}
          </Typography>
          <Button
            fullWidth
            style={{ marginTop: 10 }}
            size="small"
            color="secondary"
            variant="contained"
            onClick={clearCache}
          >
            {'Se reconnecter'}
          </Button>
        </Box>
      )}
    </Box>
  );
};
