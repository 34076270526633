import { colors } from '@material-ui/core';

export const styles = (theme) => ({
  titleButton: {
    marginLeft: theme.spacing.unit,
  },
  optionContainer: {
    alignItems: 'center',
    color: theme.palette.grey[500],
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
  },
  optionContent: {
    flex: 1,
    marginRight: theme.spacing.unit,
    overflow: 'hidden',
  },
  optionValue: {
    color: theme.palette.text.secondary,
    lineHeight: 'inherit',
  },
  trnName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
  },
  loaderContainer: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 4}px 0`,
  },
  headerContainer: {
    position: 'relative',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  hints: {
    position: 'absolute',
    top: -5,
    right: 0,
    zIndex: 2,
  },
  proContent: {
    position: 'absolute',
    top: -5,
    left: 0,
    zIndex: 2,
  },
  price: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: 'rgba(224, 225, 215, 1)',
    cursor: 'pointer',
  },
  slots: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: 'rgba(132, 189, 227, 1)',
    cursor: 'pointer',
  },
  players: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: 'rgba(61, 195, 77, 1)',
    cursor: 'pointer',
  },
  pro: {
    marginRight: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  item: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.unit * 2,
  },
  select: { minWidth: 120 },
  oHidden: { overflow: 'hidden' },
  itemContainer: {
    display: 'block',
  },
  registrationWarning: { color: '#FF7E07' },
  inProgress: { color: '#3fb917' },
  registrationClose: { color: colors.red[500] },
  full: { color: colors.red[500] },
  registrationOpen: { color: colors.green[500] },
  lifecycle: { color: theme.palette.text.primary },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    padding: 4,
    marginBottom: 15,
  },
  filterInterContainer: {
    display: 'flex',
    backgroundColor: 'white',
    flex: 1,
    padding: 2,
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
  },
  filterInterIconContainer: {
    display: 'flex',
    width: '28px',
    marginRight: '16px',
    marginLeft: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterInterContentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 8,
  },
  filterInterContentContainerItemActive: {
    display: 'flex',
    padding: 2,
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    marginRight: 4,
    backgroundColor: theme.palette.common.white,
  },
  filterInterContentContainerItemInactive: {
    display: 'flex',
    padding: 2,
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    marginRight: 4,
    backgroundColor: 'gray',
  },
  filterInterContentContainerItemAvatar: {
    textShadow: `
      1px 1px 0 #00000033,
      -1px 1px 0 #00000033,
      -1px -1px 0 #00000033,
      1px -1px 0 #00000033
        `,
    fontWeight: 800,
    fontSize: 12,
    borderRadius: 5,
    width: 32,
    height: 32,
  },
  radioGroup: {
    border: 0,
    borderRadius: 3,
    height: 48,
    padding: '0 30px',
  },
  radioButton: {
    margin: 0,
    fontSize: 12,
    height: 36,
    minWidth: 64,
    padding: 4,
    color: theme.palette.common.white,
  },
  radioButtonRoot: {
    margin: 4,
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    userSelect: 'none',
  },
  radioButtonLabelActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  radioButtonLabelInactive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  radioButtonActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  radioButtonInactive: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  radioButtonContent: {
    display: 'none',
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    paddingTop: 20,
    justifyContent: 'center',
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingBottom: 4,
  },
  lessonsContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 445px)',
  },
  loadingContainerTime: {
    fontWeight: 'bold',
    fontSize: 18,
    paddingRight: 4,
    width: 65,
    height: 48,
    alignItems: 'center',
    alignContent: 'center',
    userSelect: 'none',
  },
  loadingContainerSlotItem: {
    margin: 4,
    padding: 4,
    paddingInline: '8px !important',
    display: 'flex',
    minWidth: 48,
    height: 48,
    fontWeight: 800,
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  loadingContainerSlotItemAvatar: {
    margin: 4,
    padding: 4,
    color: 'white',
    textShadow: `
    1px 1px 0 #00000033,
    -1px 1px 0 #00000033,
    -1px -1px 0 #00000033,
    1px -1px 0 #00000033
  `,
    fontWeight: 800,
    fontSize: 14,
    borderRadius: 5,
    width: 48,
    height: 48,
  },
  loadingContainerSlotItemAvatarLabel: {
    fontWeight: 700,
    fontSize: '0.8rem',
    borderRadius: 5,
    padding: 4,
    margin: 0,
    userSelect: 'none',
  },
});
