import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Booking } from '@aps-management/primapp-common';
import {
  Avatar,
  IconButton,
  Typography,
  Tooltip,
  withWidth,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';

const testMode = false;

const measureTextWidth = (text, font = '14px Montserrat') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
};

const RenderRow = (props) => {
  const {
    detail,
    label,
    bgColor,
    handleBook,
    selectDateTime,
    slot,
    durationTime,
    classes,
    moment,
    width, // injecté par withWidth
    selectedActivity,
    handleSlotSelection, // ajouté ici
    search: { players },
    setBooking,
  } = props;

  const containerRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const resources = selectedActivity.resources || [];

  // En mode normal, on filtre les resourceIds existants
  const resourceButtons = detail.resourceIds.filter((id) =>
    resources.find((r) => r.resourceId === id));

  // En testMode, simule un grand nombre de boutons
  const testResourceIds = Array(detail.resourceIds.length * 100)
    .fill(null)
    .map((_, i) => i)
    .slice(1, detail.resourceIds.length * 100);

  const finalResourceIds = testMode ? testResourceIds : resourceButtons;

  // Fonction de calcul du visibleCount
  const recalcVisibleCount = useCallback(() => {
    if (!containerRef.current) return;
    const containerWidth = containerRef.current.offsetWidth;
    if (containerWidth <= 0) return;

    const EXTRA_WIDTH = 40; // marge/padding estimé pour chaque bouton
    const font = '14px Montserrat';
    // Calcul dynamique pour le bouton +N (on se base sur "+99" ici, avec un padding réduit)
    const reservedWidth = measureTextWidth('+99', font) + 10;

    // Calculer la largeur de chaque bouton
    const buttonWidths = finalResourceIds.map((id) => {
      const resource = resources.find((r) => r.resourceId === id) || {
        name: `Court ${id}`,
      };
      const text = resource.name || '-';
      return measureTextWidth(text, font) + EXTRA_WIDTH;
    });

    // Première étape : calcul avec réservation pour chaque bouton non final
    let sumReserved = 0;
    let count = 0;
    for (let i = 0; i < buttonWidths.length; i++) {
      // Si on a encore des boutons après celui-ci, on ajoute la largeur réservée pour le +N
      const hasHidden = i < buttonWidths.length - 1;
      const needed = hasHidden
        ? buttonWidths[i] + reservedWidth
        : buttonWidths[i];
      if (sumReserved + needed <= containerWidth) {
        sumReserved += buttonWidths[i];
        count++;
      } else {
        break;
      }
    }

    // Deuxième étape : si des boutons restent et qu'il y a de la place pour le suivant sans réserver,
    // on l'ajoute (puisque ce bouton serait le dernier, pas besoin de réserver l'espace pour +N).
    if (count < buttonWidths.length) {
      const leftover = containerWidth - sumReserved;
      if (leftover >= buttonWidths[count]) {
        count++;
        sumReserved += buttonWidths[count];
      }
    }
    setVisibleCount(count);
  }, [finalResourceIds, resources]);

  // Calcul initial
  useLayoutEffect(() => {
    recalcVisibleCount();
  }, [recalcVisibleCount, width]);

  // Observer les changements de taille pour recalculer le visibleCount
  useEffect(() => {
    if (containerRef.current && containerRef.current instanceof Element) {
      const resizeObserver = new ResizeObserver(() => {
        recalcVisibleCount();
      });
      resizeObserver.observe(containerRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
    return undefined;
  }, [recalcVisibleCount]);

  // Définir la liste des éléments cachés
  const hiddenItems = finalResourceIds
    .slice(visibleCount)
    .map(
      (id) =>
        resources.find((r) => r.resourceId === id) || {
          name: `Test ${id}`,
          resourceId: id,
        },
    );
  const hiddenCount = finalResourceIds.length - visibleCount;

  // Gestion du menu
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleClick = ({ slot: slotClicked, detail: detailClicked, resourceIndex }) => {
    // Mettre à jour le créneau sélectionné dans le contexte via handleSlotSelection
    if (handleSlotSelection) {
      const selectedResourceId = detailClicked.resourceIds[resourceIndex];
      const resource = resources.find((r) => r.resourceId === selectedResourceId);
      const resourceName = resource ? resource.name : 'Non spécifié';
      handleSlotSelection({
        resourceName,
        slot: slotClicked,
      });
    }

    const timeStr = (slotClicked && slotClicked.hour) ? moment(slotClicked.hour, 'HHmm').format('HH:mm') : '00:00';
    const startsAt = moment(`${selectDateTime.format('YYYY-MM-DD')}T${timeStr}`).format('YYYY-MM-DDTHH:mm:ss');
    const endsAt = moment(`${selectDateTime.format('YYYY-MM-DD')}T${timeStr}`)
      .add(durationTime.time, 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');
    const price = detailClicked.pricing
      ? detailClicked.pricing.reduce((sum, item) => sum + item.price, 0)
      : 0;

    // Créer un objet avec les informations du créneau pour l'écran de paiement
    const selectedResourceId = detailClicked.resourceIds[resourceIndex];
    const resource = resources.find((r) => r.resourceId === selectedResourceId);
    const resourceName = resource ? resource.name : 'Non spécifié';
    const bookingInfo = {
      id: uuid(),
      startsAt,
      endsAt,
      price,
      pricing: detailClicked.pricing,
      activity: selectedActivity,
      duration: durationTime.time,
      resourceName,
      typeId: slot.activitySlots[0].typeId,
      resourceId: resource.resourceId,
    };
    

    setBooking({
      from: 'public-activity',
      selectedSlot: `${timeStr} - ${moment(endsAt).format('HH:mm')}`,
      options: [`Durée: ${durationTime.time} min`, `Prix: ${(price / 100).toFixed(2)} €`],
      bookingInfo,
      players,
    });

    // Naviguer vers l'écran de sélection de paiement
    const currentPath = props.history.location.pathname;
    const baseUrl = currentPath.substring(0, currentPath.indexOf('/activity'));
    props.history.push({
      pathname: `${baseUrl}/activity/payment-selection`,
      state: {
        selectedSlot: `${timeStr} - ${moment(endsAt).format('HH:mm')}`,
        options: [`Durée: ${durationTime.time} min`, `Prix: ${(price / 100).toFixed(2)} €`],
        bookingInfo,
        players,
      },
    });

    // Appeler également la fonction handleBook d'origine
    handleBook(bookingInfo);
  };

  // En mode non étendu, on affiche les boutons jusqu'à visibleCount
  const buttonsToDisplay = finalResourceIds.slice(0, visibleCount);


  return (
    <div
      ref={containerRef}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {label && selectedActivity.okType && (
        <Avatar
          variant="square"
          className={classes.loadingContainerSlotItemAvatar}
          style={{
            backgroundColor: bgColor,
            border: `2px solid ${bgColor}`,
            fontWeight: 800,
            marginRight: 8,
          }}
        >
          {label.charAt(0)}
        </Avatar>
      )}
      {/* On attache le ref ici à un élément DOM natif */}
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {buttonsToDisplay.map((id, i) => {
          const resource = resources.find((r) => r.resourceId === id) || {
            name: `Test ${id}`,
          };
          return (
            <Tooltip
              key={`${detail.typeId}-${i}`}
              title={resource.name}
            >
              <IconButton
                className={classes.loadingContainerSlotItem}
                style={{ margin: 4 }}
                onClick={() => handleClick({ slot, detail, resourceIndex: i })}
              >
                <Typography
                  noWrap
                  style={{
                    color: '#000000',
                    fontFamily: 'Montserrat',
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {resource.name || '-'}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>
      {hiddenCount > 0 && (
        <div>
          <IconButton
            className={classes.loadingContainerSlotItem}
            aria-label="More"
            aria-controls="hidden-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            style={{ borderRadius: 5, margin: 4 }}
          >
            <Typography
              noWrap
              style={{
                color: '#000000',
                fontFamily: 'Montserrat',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              +{hiddenCount}
            </Typography>
          </IconButton>
          <Menu
            id="hidden-menu"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5, // ITEM_HEIGHT * 4.5, ici ITEM_HEIGHT = 48 par exemple
                width: 200,
              },
            }}
          >
            {hiddenItems.map((item, index) => (
              <MenuItem
                key={item.resourceId || index}
                onClick={() => handleClick({ slot, detail, resourceIndex: visibleCount + index })}
              >
                <Typography
                  variant="inherit"
                  noWrap
                  style={{
                    color: '#000000',
                    fontFamily: 'Montserrat',
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
};

RenderRow.propTypes = {
  detail: PropTypes.object.isRequired,
  label: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  handleBook: PropTypes.func.isRequired,
  selectDateTime: PropTypes.object.isRequired, // instance moment
  slot: PropTypes.object.isRequired,
  durationTime: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  moment: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  selectedActivity: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleSlotSelection: PropTypes.func.isRequired, // ajouté ici
};

const mapStateToProps = ({ bookingSearch }) => ({
  search: bookingSearch
});
export default connect(
  mapStateToProps,
  Booking.actions,
)(withRouter(withWidth()(RenderRow)));