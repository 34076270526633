import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Activity } from '@aps-management/primapp-common';
import { styles } from '../../styles/Activity.styles';
import RenderDisponibility from './RenderDisponibility';

/**
 * Mappe l'état Redux aux propriétés du composant.
 * @param {Object} state - L'état global de Redux.
 * @returns {Object} Les propriétés extraites du state.
 */
const mapStateToProps = ({ app, activityData, activitySearch }) => ({
  golf: app.golf,
  data: activityData,
  account: app.account,
  search: activitySearch,
});

export default connect(
  mapStateToProps,
  Activity.actions,
)(withWidth()(withStyles(styles)(RenderDisponibility)));
