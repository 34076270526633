import React from 'react';
import { helpers } from '@aps-management/primapp-common';
import i18n from '_utils/i18n';
import moment from 'moment';
import SearchOption from './SearchOption';

const DateOption = ({
  classes, active, onClick, date,
}) => {
  const m = moment(date);
  const value = m.isValid()
    ? helpers.string.ucfirst(i18n.l('date.formats.long', m.toDate()))
    : helpers.string.ucfirst(i18n.l('date.formats.long', moment().toDate())); // ou une valeur par défaut

  return (
    <SearchOption
      label="Date"
      value={value}
      classes={classes}
      onClick={onClick}
      isExpanded={active}
    />
  );
};

export default DateOption;
