import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Typography, Paper, Grid, Divider, Fab,
  TextField,
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Screen } from '_components/core';
import { ArrowForwardIos as ForwardIcon } from '@material-ui/icons';
import { InfoOutlined, People } from '@material-ui/icons';
import i18n from '_utils/i18n';
import {
  Activity,
  App,
  Booking,
  HELP_URL,
} from '@aps-management/primapp-common';
import PaymentMethods from '_components/PaymentMethods';
import ActivityContext from './contexts/ActivityContext';
import masterClient from '_utils/masterClient';
import { useContextSelector } from '@fluentui/react-context-selector';
import defaultTheme from '../../config/defaultTheme.json';

const styles = (theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing.unit * 3,
    maxWidth: 600,
    margin: '0 auto',
  },
  rightPanel: {
    position: 'absolute',
    right: -300 - (theme.spacing.unit * 1),
    top: 4,
    width: 300,
    height: '100%',
    margin: '0 auto',
    transition: 'all cubic-bezier(0.5, 0, 0.5, 1) 0.5s',
    boxShadow: '0 2px 5px rgba(0,0,0,0)',
  },
  rightPanelContent: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    border: `1px solid ${theme.palette.divider}`,
  },
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 3,
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 40,
    marginRight: theme.spacing.unit * 2,
  },
  title: {
    fontWeight: 600,
  },
  section: {
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    margin: theme.spacing.unit * 2,
  },
  paymentMethod: {
    marginBottom: theme.spacing.unit * 2,
  },
  actions: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  value: {
    fontWeight: 400,
  },
  margin: {
    position: 'absolute',
    width: 40,
    height: 40,
    right: -20 - (theme.spacing.unit / 2),
  },
});

const PaymentSelection = ({ classes, options: golfOptions, account, golf }) => {
  const theme = (golfOptions && golfOptions.themeMUI) || defaultTheme;
  const location = useLocation();
  const history = useHistory();
  const {
    error,
    load,
    isPublic,
    setError,
  } = useContextSelector(ActivityContext, (c) => c);
  const [showRightPanel, setShowRightPanel] = useState(true);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { selectedSlot, options, bookingInfo, players } = {
    selectedSlot: 'Non spécifié',
    options: [],
    bookingInfo: null,
    players: [],
    ...location?.state,
  };

  const [paymentMethod, setPaymentMethod] = useState(0);
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);

  const paymentMethods = App.functions.getPaymentMethods(account, golf, 'activity');
  console.log(bookingInfo.price)
  let buttonTxt = bookingInfo.price !== 0 ? `${(paymentMethod === 1 ? 'Valider' : 'Payer')}`
    + ` ${i18n.l('currency', bookingInfo.price / 100)}` : 'Valider';

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (paymentMethod !== 1 && bookingInfo.price === 0) {
      setPaymentMethod(1);
    }
  }, [bookingInfo.price, paymentMethod])

  const handleConfirm = () => {
    setIsLoading(true);
    const path = paymentMethod === 0 ? '/activity/payment' : '/activity/confirmation';

    if (paymentMethod !== 0) {
      Activity.api.activityBook(masterClient, {
        selectedSlot,
        options,
        bookingInfo,
        paymentMethod,
        comment,
        golf,
        players: location.state.players.map((player, index) => {
          if (index === 0) {
            return ({
              ...account,
              ...player,
            });
          }
          return ({
            ...player,
          });
        }),
      }).then(() => {
        setIsLoading(false);
        history.push({ 
          pathname: path,
          state: {
            selectedSlot,
            options,
            bookingInfo,
            paymentMethod,
            players,
            comment,
          },
        });
      })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    } else {
      setIsLoading(false);
      history.push({
        pathname: path,
        state: {
          selectedSlot,
          options,
          bookingInfo,
          paymentMethod,
          players,
          comment,
        },
      });
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const pricing = bookingInfo?.pricing || [];
  const activityDate = bookingInfo.startsAt
    ? formatDate(bookingInfo.startsAt)
    : 'Non spécifié';


  return (
    <Screen
      noDrawer={isPublic}
      layout={'fluid-large'}
      ForwardIcon={ForwardIcon}
      helpURL={`${HELP_URL}/r%C3%A9server`}
      onRetry={load}
      title="Activités"
      error={error}
    >
      <Paper className={classes.root} elevation={2}>
        <Paper
          className={classes.rightPanel}
          style={{
            zIndex: -1,
            paddingInline: 24,
            paddingBlock: 16,
            paddingLeft: 32,
            height: 'calc(100% - 8px)',
            transform: showRightPanel ? 'translateX(0)' : 'translateX(-100%)',
            transition: 'all cubic-bezier(0.5, 0, 0.5, 1) 0.5s',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          }}
          elevation={2}
        >
          {showRightPanel && players && players.length > 0 && players.map((player, index) => (
            <div key={index} className={classes.rightPanelContent} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className={classes.label} style={{ fontWeight: 700 }}>
                {`${player.firstname} ${player.lastname}`}
              </Typography>
              {console.log(bookingInfo)}
              <Typography className={classes.label} style={{ fontWeight: 700 }}>{bookingInfo?.activity?.invoiceType === 0 ? '' && (pricing[index]?.price || 0) !== 0 : `${i18n.l('currency', parseInt(pricing[index]?.price || 0, 10) / 100)}`}</Typography>
            </div>
          ))}
          {showRightPanel && players && players.length === 0 && (
            <Typography className={classes.label} style={{ fontWeight: 700, marginTop: 16 }}>
              Aucun joueur
            </Typography>
          )}
        </Paper>
        <Fab
          size="medium"
          color="secondary"
          aria-label="Add"
          className={classes.margin}
          style={{
            zIndex: 1000,
            transition: 'all cubic-bezier(0.5, 0, 0.5, 1) 0s',
            transform: showRightPanel ? 'translateY(0)' : 'translateY(-1px)',
            backgroundColor: showRightPanel ? theme.palette.primary.main : theme.palette.primary.dark,
          }}
          onClick={() => setShowRightPanel(!showRightPanel)}
        >
          <People />
        </Fab>
        <div className={classes.header}>
          <InfoOutlined className={classes.icon} />
          <Typography variant="h5" component="h1" className={classes.title}>
          Récapitulatif de la réservation
          </Typography>
        </div>
      <Typography variant="h6" gutterBottom>
        Détails
        </Typography>

        <div className={classes.section}>
          <Grid container spacing={16}>

            <Grid item xs={5}>
              <Typography className={classes.label}>Date :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.value}>{activityDate}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.label}>Créneau choisi :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.value}>{selectedSlot}</Typography>
            </Grid>
            {bookingInfo && (
              <>
                <Grid item xs={5}>
                  <Typography className={classes.label}>Activité :</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.value}>{`${bookingInfo?.activity?.name} (${bookingInfo?.resourceName})`}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={5}>
              <Typography className={classes.label}>Joueurs :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.value}>{players.length}</Typography>
            </Grid>
          </Grid>
          {options && options.length > 0 && (
            <>
              <Divider className={classes.divider} />
              <Typography variant="h6" gutterBottom>
                Options sélectionnées
              </Typography>
              <Grid container spacing={8}>
                {options.map((option, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography variant="body2">{option}</Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <Divider className={classes.divider} />

          {bookingInfo && bookingInfo.price !== undefined && (
            <Grid container spacing={16}>
              <Grid item xs={5}>
                <Typography className={classes.label}>Montant total :</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.value} style={{ fontWeight: 600 }}>
                  {(bookingInfo.price / 100).toFixed(2)} €
                </Typography>
              </Grid>
            </Grid>
          )}
          <Divider className={classes.divider} />
          <Typography variant="h6" gutterBottom>
            Commentaire
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={5}
                style={{ border: '1px solid #E0E0E0', borderRadius: 4, paddingInline: 8, paddingBlock: 4, resize: 'none' }}
                value={comment}
                onChange={(e) => e.target.value.length <= 255 ? setComment(e.target.value) : null}
              />
            </Grid>
          </Grid>
        </div>
        {bookingInfo && bookingInfo.price !== 0 && (
          <div className={classes.section}>
          <Typography variant="h6" gutterBottom>
              Choisissez votre moyen de paiement
            </Typography>
            <PaymentMethods
              selected={paymentMethod}
              amount={bookingInfo.price}
              methods={paymentMethods}
              onChange={setPaymentMethod}
              onChangeAcceptTerms={setAreTermsAccepted}
            />
          </div>
        )}
        <div className={classes.actions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={isLoading || (!areTermsAccepted && bookingInfo.price !== 0)}  
          >
            {isLoading ? 'Chargement...' : buttonTxt}
          </Button>
        </div>
      </Paper>
    </Screen>
  );
};

const mapStateToProps = ({ bookingData, app, bookingSearch }) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
  search: bookingSearch
});
const mapDispatchToProps = { ...App.actions, ...Booking.actions };

const StyledComponent = withStyles(styles)(PaymentSelection);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledComponent);