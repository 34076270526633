import React from 'react';
import Icon from '@mdi/react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { mdiCreditCard, mdiCashRegister } from '@mdi/js';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { AccountWallet } from '@aps-management/primapp-common';
import {
  List,
  Paper,
  Radio,
  ListItem,
  ListItemText,
  Link,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import masterClient from '_utils/masterClient';

/* */
const styles = {
  item: { paddingLeft: 6 },
  balance: {
    fontWeight: 100,
    fontSize: 20,
  },
};

/* */
class PaymentMethods extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      wallet: null,
      acceptTerms: false,
      isLoadingWallet: false,
    };
  }

  /* */
  componentDidMount() {
    this.setState({ isLoadingWallet: true });
    const {
      account,
      golf,
      methods,
    } = this.props;

    // Check si le client a un compte client
    if (methods.includes(2)) {
      AccountWallet.api.get(masterClient, {
        golfId: golf.id,
        accountId: account.id,
      })
        .then(wallet => this.setState({ wallet }))
        .catch(() => null) // erreur = pas de compte client
        .finally(() => this.setState({ isLoadingWallet: false }));
    }
  }

  /* */
  handleChangeAcceptTerms = (event) => {
    const { onChangeAcceptTerms } = this.props;

    this.setState({ acceptTerms: event.target.checked });
    if (onChangeAcceptTerms) onChangeAcceptTerms(event.target.checked);
  }

  /* */
  renderWalletMethod() {
    const {
      classes,
      amount,
      selected,
      onChange,
    } = this.props;
    const { wallet, isLoadingWallet } = this.state;

    if (isLoadingWallet) return null;

    if (!wallet) return null;

    let disabled = false;
    const remainingBalance = wallet.balance - amount;
    if (wallet.creditLimit !== null
      && remainingBalance < (-1) * wallet.creditLimit) {
      disabled = true;
    }

    let color = 'primary';
    let secondary = (
      <Typography variant="caption" color={color}>
        {`Futur solde : ${i18n.l('currency', remainingBalance / 100)}`}
      </Typography>
    );
    if (disabled) {
      color = 'error';
      secondary = (
        <Typography variant="caption" color={color}>
          {'Vous n\'avez pas les fonds suffisants.'}
        </Typography>
      );
    }

    return (
      <ListItem
        button
        divider
        disableRipple
        disabled={disabled}
        className={classes.item}
        onClick={() => onChange(2, { wallet })}
      >
        <Radio
          tabIndex={-1}
          disabled={disabled}
          checked={selected === 2}
        />
        <ListItemText
          primary="Payer par compte client"
          secondary={secondary}
        />
      <Typography
        color={color}
        className={classes.balance}
      >
        {i18n.l('currency', wallet.balance / 100)}
      </Typography>
      </ListItem>
    );
  }

  /* */
  render() {
    const {
      classes,
      methods,
      selected,
      onChange, // moyen de paiement
    } = this.props;

    const { acceptTerms } = this.state;

    const AcceptLabel = (
      <Typography>
        {"J'ai lu et j'accepte les "}
        <Link
          rel="noopener"
          target="_blank"
          color="textSecondary"
          href="/terms-conditions">
          {"Conditions générales d'utilisation."}
        </Link>
      </Typography>
    );

    return (
      <Box>
        <Paper>
          <List disablePadding>
            {methods.includes(0) && (
              <ListItem
                button
                divider
                disableRipple
                onClick={() => onChange(0, {})}
                className={classes.item}>
                <Radio
                  tabIndex={-1}
                  checked={selected === 0} />
                <ListItemText
                  primary="Payer par carte bancaire" />
                <Icon path={mdiCreditCard} size={1.5} />
              </ListItem>
            )}
            {methods.includes(1) && (
              <ListItem
                button
                divider
                disableRipple
                onClick={() => onChange(1, {})}
                className={classes.item}>
                <Radio
                  tabIndex={-1}
                  checked={selected === 1} />
                <ListItemText
                  primary="Payer sur place"
                  secondary="Présentez vous à l'accueil pour régler" />
                <Icon path={mdiCashRegister} size={1.5} />
              </ListItem>
            )}
            {this.renderWalletMethod()}
          </List>
        </Paper>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                value="1"
                name="acceptTerms"
                color="secondary"
                checked={acceptTerms}
                className={classes.checkboxCtrl}
                onChange={this.handleChangeAcceptTerms} />
            }
            label={AcceptLabel} />
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});
const StyledComponent = withStyles(styles)(PaymentMethods);

export default connect(mapStateToProps)(StyledComponent);
