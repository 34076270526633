import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { useContextSelector } from '@fluentui/react-context-selector';
import DurationButton from '../controls/DurationButton';
import ActivityContext from '../../contexts/ActivityContext';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  selectedButton: {
    border: `2px solid ${theme.palette.secondary.main}`,
    // Autres styles pour le bouton sélectionné
  },
});

const SetDuration = ({
  onDurationSelect, // callback pour renvoyer le pgbCodeProd, si nécessaire
  classes,
}) => {
  const {
    selectedActivity,
    selectedDuration,
    setSelectedDuration,
    setActiveOption,
  } = useContextSelector(ActivityContext, (c) => c);

  let durations = (selectedActivity && selectedActivity.hourPrice) || [];

  // Si l'activité n'est pas de type "ok" et possède des types,
  // on ne garde que celles du premier type
  if (
    selectedActivity
    && selectedActivity.okType !== true
    && selectedActivity.type
    && selectedActivity.type.length > 0
  ) {
    const firstTypeId = selectedActivity.type[0].typeId;
    durations = durations.filter((duration) => duration.typeId === firstTypeId);
  }

  // Élimination des doublons :
  // - Pour okType === true, l'unicité se base uniquement sur "time"
  // - Sinon, sur une clé composite (time, typeId, pgbCodeProd)
  const uniqueDurations = durations.reduce((acc, duration) => {
    const key = selectedActivity.okType === true
      ? duration.time
      : `${duration.time}-${duration.typeId}-${duration.pgbCodeProd}`;
    if (
      !acc.some((d) => {
        const compareKey = selectedActivity.okType === true
          ? d.time
          : `${d.time}-${d.typeId}-${d.pgbCodeProd}`;
        return compareKey === key;
      })
    ) {
      acc.push(duration);
    }
    return acc;
  }, []);

  // Tri des durées par ordre croissant (basé sur la valeur "time")
  const sortedDurations = uniqueDurations.sort((a, b) =>
    a.time - b.time);

  return (
    <Box m={1} mb={3}>
    <Typography
      paragraph
      component="p"
      variant="h6">
      {'Quelle durée ?'}
    </Typography>
    <Paper className={classes.paper}>
      <Typography gutterBottom>Durée du créneau</Typography>
      <Box className={classes.container}>
        {sortedDurations.map((duration, index) => (
          <DurationButton
            key={`${duration.hourPriceId}-${duration.typeId}`}
            duration={duration}
            defaultSelected={index === 0}
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
            setActiveOption={setActiveOption}
            onDurationSelect={onDurationSelect}
            classes={classes}
          />
        ))}
      </Box>
    </Paper>
    </Box>
  );
};

export default withStyles(styles)(SetDuration);
