/**
 * Génère une couleur hexadécimale à partir d'une chaîne.
 * @param {string} str - La chaîne à partir de laquelle générer la couleur.
 * @returns {string} La couleur hexadécimale générée.
 */
export const generateColorFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + hash * 31;
  }
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = Math.floor(hash / 256 ** i) % 256;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

/**
 * Retourne une couleur de texte contrastée (noir ou blanc) en fonction d'une couleur de fond.
 * @param {string} hexColor - La couleur de fond en hexadécimal.
 * @returns {string} '#000000' ou '#FFFFFF' selon la luminosité.
 */
export const getContrastColor = (hexColor) => {
  const sanitizedColor = hexColor.replace('#', '');
  const r = parseInt(sanitizedColor.substring(0, 2), 16);
  const g = parseInt(sanitizedColor.substring(2, 4), 16);
  const b = parseInt(sanitizedColor.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness >= 128 ? '#000000' : '#FFFFFF';
};

/**
 * Génère une couleur de fond et une couleur de texte contrastée à partir d'une chaîne.
 * @param {string} str - La chaîne pour générer les couleurs.
 * @returns {Object} Objet contenant { bgColor, textColor }.
 */
export const generateColorWithContrast = (str) => {
  const bgColor = generateColorFromString(str);
  const textColor = getContrastColor(bgColor);
  return { bgColor, textColor };
};
